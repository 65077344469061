import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import logoImg from 'assets/images/loghi/trusty-logo-black.svg'

import './Splash.css'

const Splash = ({ loading }) => {
  const [myDisplay, setMyDisplay] = useState('initial')

  useEffect(() => {
    if (loading) {
      setMyDisplay('initial')
    } else {
      setMyDisplay('none')
    }
  }, [loading])

  return (
    <div id='splash' style={{ display: myDisplay }}>
      <div style={{ textAlign: 'center', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
        <img src={logoImg} alt='' />
      </div>
    </div>
  )
}

export default Splash

Splash.propTypes = {
  loading: PropTypes.bool
}
