import React from 'react'
import { useTranslation } from 'react-i18next'

export let dataCards = []

const GetDataCards = () => {
  const { t } = useTranslation(['home'])
  dataCards = [
    {
      id: 1,
      title: (
        <>
          {t('home:sectionCard:card1:title1')} <br></br> {t('home:sectionCard:card1:title2')}
        </>
      ),
      image: require('assets/images/home/home/1.png'),
      background: 'bg-grey',
      text: (
        <>
          {t('home:sectionCard:card1:description')}
        </>
      )
    },
    {
      id: 2,
      title:
      <>
        {t('home:sectionCard:card2:title1')} {t('home:sectionCard:card2:title2')}
      </>,
      image: require('assets/images/home/home/2.png'),
      background: 'bg-yellow',
      text: (
        <>
          {t('home:sectionCard:card2:description')}
        </>
      )
    },
    {
      id: 3,
      title: (
        <>
          {t('home:sectionCard:card3:title1')} <br></br> {t('home:sectionCard:card3:title2')}
        </>
      ),
      image: require('assets/images/home/home/3.png'),
      background: 'bg-green',
      text: (
        <>
          {t('home:sectionCard:card3:description')}
        </>
      )
    },
    {
      id: 4,
      title: (
        <>
          {t('home:sectionCard:card4:title1')} <br></br> {t('home:sectionCard:card4:title2')}
        </>
      ),
      image: require('assets/images/home/home/4.png'),
      background: 'bg-sky',
      text: (
        <>
          {t('home:sectionCard:card4:description')}
        </>
      )
    },
    {
      id: 5,
      title: (
        <>
          {t('home:sectionCard:card5:title1')} <br></br> {t('home:sectionCard:card5:title2')}
        </>
      ),
      image: require('assets/images/home/home/5.png'),
      background: 'bg-orange',
      text: (
        <>
          {t('home:sectionCard:card5:description1')} <span className='freeDoka'>{t('home:sectionCard:card5:bold')}</span> {t('home:sectionCard:card5:description2')}
        </>
      )
    }
  ]

  return dataCards
}

export default GetDataCards
