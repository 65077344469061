import React from 'react'

import SectionIntro from './Sections/SectionIntro'
import SectionTop from './Sections/SectionTop'
import SectionCards from './Sections/SectionCards'
// import SectionPricing from './Sections/SectionPricing'
import SectionQrCode from './Sections/SectionQrCode'
import SectionFooter from './Sections/SectionFooter'
import { useTranslation } from 'react-i18next'
// import { dynH } from 'Utils'

import './style.css'
import MetaTags from 'react-meta-tags'

const MobileVersion = () => {
  const { t } = useTranslation(['common'])
  // const [resizeH, setResizeH] = useState(0)

  return (
    <>
      <MetaTags>
        <title>{t('metaTitle')}</title>
        <meta name="description" content={t('metaDescription')} />
        <meta property="og:title" content={t('metaTitle')} />
        {/* <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
      <div
        id='timeline'
        style={{ top: '0', position: 'absolute', height: '100vh' }}
      ></div>
      <div id="allContain" style={{ background: 'white' }}>
        <SectionIntro />
        <SectionTop />
        <div id='card-how-mobile'>
          <div>
            <h1 className='freeDoka' style={{ fontSize: '7rem' }}>
              {t('common:come-funziona')}
            </h1>
          </div>
        </div>
        <SectionCards />
        <SectionQrCode />
        {/* <SectionPricing /> */}
      </div>
      <SectionFooter />
      <div id='mobile-empty-space'></div>
    </>
  )
}

export default MobileVersion
