import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Grid } from '@material-ui/core'
import configuration from 'configuration'
import Modal from '../../../../../components/Modal'
// import PropTypes from 'prop-types'
import DialogContent from '@material-ui/core/DialogContent'
import YouTube from 'react-youtube'

import ModalWhitePaper from '../../../../../components/ModalWhitePaper'

import logoFooter from 'assets/images/loghi/logo-footer-black.png'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const SectionFooter = () => {
  const { t } = useTranslation(['home'])
  const [modalWhitePaperVisible, setModalWhitePaperVisible] = useState(false)
  const [modalSpotVisible, setModalSpotVisible] = useState(false)
  const [modalFilosofiaVisible, setModalFilosofiaVisible] = useState(false)
  const handleClickWhitePaper = () => setModalWhitePaperVisible(true)
  const handleClickSpot = () => setModalSpotVisible(true)
  const handleClickFilosofia = () => setModalFilosofiaVisible(true)
  const handleCloseWhitepaper = () => setModalWhitePaperVisible(false)

  const videoOnReady = (event) => {
    // setupListener()
  }

  // render() {
  const opts = {
    height: '400px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1
    }
  }
  const [scroll, setScroll] = React.useState('paper')
  useEffect(() => {
    setScroll('paper')
  }, [])

  return (
    <>
      <div className={'footer-full-screen'} id='scheda-footer'>
        <Container maxWidth='lg'>
          <Grid container spacing={1} justify='center' alignItems='center'>
            <Grid item xs={12} sm={6}>
              <a className='navbar-brand' href={configuration.pageLinks.home}>
                <img
                  src={logoFooter}
                  style={{ height: '85px', width: 'auto' }}
                  alt=''
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ul>
                <li>
                  <div onClick={handleClickWhitePaper}>
                    <h1 className='freeDoka desk-footer-title' id='titolo1'>
                      <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title1')}
                    </h1>
                  </div>
                </li>
                <Modal
                  className='desktopModal'
                  visible={modalWhitePaperVisible}
                  setVisible={setModalWhitePaperVisible}
                  title={t('home:sectionFooter:title1')}
                  closable={true}
                >
                  <div className='modal-app desktop'>
                    <ModalWhitePaper
                      parentCallback={handleCloseWhitepaper}
                    />
                  </div>
                </Modal>
                <li>
                  <div onClick={handleClickSpot}>
                    <h1 className='freeDoka desk-footer-title' id='titolo2'>
                      <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title2')}
                    </h1>
                  </div>
                </li>
                <Modal
                  className='desktopModal video'
                  visible={modalSpotVisible}
                  setVisible={setModalSpotVisible}
                  title='A True Story'
                  closable={true}
                >
                  <div className='modal-app desktop'>
                    <YouTube
                      videoId='oVnfeLefTH0'
                      id='player'
                      className='ytVideo'
                      containerClassName='videoContainer'
                      opts={opts}
                      onReady={videoOnReady}
                    // onPlay={func}
                    // onPause={func}
                    // onEnd={func}
                    // onError={func}
                    // onStateChange={func}
                    // onPlaybackRateChange={func}
                    // onPlaybackQualityChange={func}
                    />
                  </div>
                </Modal>
                <li>
                  <div onClick={handleClickFilosofia}>
                    <h1 className='freeDoka desk-footer-title' id='titolo3'>
                      <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title3')}
                    </h1>
                  </div>
                </li>
                <Modal
                  className='desktopModal filosofia'
                  visible={modalFilosofiaVisible}
                  setVisible={setModalFilosofiaVisible}
                  title={t('home:sectionFooter:title3')}
                  closable={true}
                >
                  <DialogContent dividers={scroll === 'paper'}>
                    <div className='modal-app desktop scrollY'>
                      <p>
                        {t('home:sectionFooter:filosofia1')}
                      </p>
                      <p>
                        {t('home:sectionFooter:filosofia2')}
                      </p>
                      <p>
                        {t('home:sectionFooter:filosofia3')}
                      </p>
                      <p>
                        {t('home:sectionFooter:filosofia4')}
                      </p>
                    </div>
                  </DialogContent>
                </Modal>

                <li>
                  <a href={configuration.pageLinks.press}>
                    <h1 className='freeDoka desk-footer-title' id='titolo4'>
                      <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title4')}
                    </h1>
                  </a>
                </li>
              </ul>
              <div>
                <Container className="socialHomeSection" id='titolo2'>
                  <a href='https://www.linkedin.com/company/trusty-a-true-story' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-linkedin' />
                  </a>
                  <a href='https://www.facebook.com/trustyItaly' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-facebook' />
                  </a>
                  <a href='https://www.instagram.com/trustyofficial_/' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-instagram' />
                  </a>
                  <a href='https://www.youtube.com/channel/UCC-Yqqtk-OlA_6oTsVCEX0A' target='_blank' rel='noopener noreferrer'>
                    <i className='fab fa-youtube' />
                  </a>
                </Container>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        style={{ witdh: '100vw', height: '350vh', position: 'relative' }}
        id='empty-div'
      ></div>
    </>
  )
}

export default SectionFooter
