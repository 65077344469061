import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const HowItWork = ({ visibility }) => {
  const { t } = useTranslation(['common'])
  if (document.URL.indexOf('press') >= 0) {
    return ('')
  } else if (document.URL.indexOf('about') >= 0) {
    return ('')
  } else {
    return (
      <span
        className='menu-item'
        id='howItWork'
      >
        {t('common:come-funziona')}
      </span>
    )
  }
}

export default HowItWork

HowItWork.propTypes = {
  visibility: PropTypes.bool
}
