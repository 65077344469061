import React from 'react'
// import { useTranslation } from 'react-i18next'
import SchedaHome from 'components/SchedaHome'
import GetDataCards from 'components/SchedaHome/dataCards'

const SectionCards = () => {
  const dataCards = GetDataCards()

  return (
    <div id='home-schede'>
      {dataCards.map((item, i) => (
        <SchedaHome
          key={item.id}
          id={item.id}
          title={item.title}
          image={item.image}
          text={item.text}
          background={item.background}
          left={!(i % 2)}
        />
      ))}
    </div>
  )
}

export default SectionCards
