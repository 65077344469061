import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import YouTube from 'react-youtube'
import Modal from '../../../../../components/Modal'
import ModalWhitePaper from '../../../../../components/ModalWhitePaper'
import { Container } from '@material-ui/core'
import configuration from 'configuration'

import logoFooter from 'assets/images/loghi/logo-footer-black.png'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const SectionFooter = () => {
  const { t } = useTranslation(['home'])
  // var player, iframe
  // var $ = document.querySelector.bind(document)

  // const [modalVisible, setModalVisible] = useState(false)
  // const handleClick = () => setModalVisible(true)
  const [modalWhitePaperVisible, setModalWhitePaperVisible] = useState(false)
  const [modalSpotVisible, setModalSpotVisible] = useState(false)
  const [modalFilosofiaVisible, setModalFilosofiaVisible] = useState(false)
  const handleClickWhitePaper = () => setModalWhitePaperVisible(true)
  const handleClickSpot = () => setModalSpotVisible(true)
  const handleClickFilosofia = () => setModalFilosofiaVisible(true)
  const handleCloseWhitepaper = () => setModalWhitePaperVisible(false)

  const videoOnReady = (event) => {
    // setupListener()
  }

  // render() {
  const opts = {
    height: '400px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1
    }
  }

  // const setupListener = () => {
  //   $('ytp-large-play-button').addEventListener(
  //     'click',
  //     playFullscreen
  //   )
  // }

  // const playFullscreen = () => {
  //   player.playVideo()

  //   var requestFullScreen =
  //     iframe.requestFullScreen ||
  //     iframe.mozRequestFullScreen ||
  //     iframe.webkitRequestFullScreen
  //   if (requestFullScreen) {
  //     requestFullScreen.bind(iframe)()
  //   }
  // }

  return (
    <>
      <div className={'footer-full-screen-mobile'} id='scheda-footer-mobile mobileSection'>
        <div>
          <ul>
            <li>
              <div onClick={handleClickWhitePaper}>
                <h1
                  className='freeDoka mobile-footer-title'
                  id='titolo-mobile1'
                >
                  <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title1')}
                </h1>
              </div>
            </li>
            <Modal
              className='mobileModal'
              visible={modalWhitePaperVisible}
              setVisible={setModalWhitePaperVisible}
              title={t('home:sectionFooter:title1')}
              closable={true}
            >
              <div
                className='modal-app mobile'
              >
                <ModalWhitePaper
                  parentCallback={handleCloseWhitepaper}
                />
              </div>
            </Modal>
            <li>
              <div onClick={handleClickSpot}>
                <h1
                  className='freeDoka mobile-footer-title'
                  id='titolo-mobile2'
                >
                  <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title2')}
                </h1>
              </div>
              <Modal
                className='mobileModal video'
                visible={modalSpotVisible}
                setVisible={setModalSpotVisible}
                title='A True Story'
                closable={true}
              >
                <div
                  className='modal-app mobile'
                >
                  <YouTube
                    videoId='oVnfeLefTH0'
                    id='player'
                    className='ytVideo'
                    containerClassName='videoContainer'
                    opts={opts}
                    onReady={videoOnReady}
                  // onPlay={func}
                  // onPause={func}
                  // onEnd={func}
                  // onError={func}
                  // onStateChange={func}
                  // onPlaybackRateChange={func}
                  // onPlaybackQualityChange={func}
                  />
                </div>
              </Modal>
            </li>
            <li>
              <div onClick={handleClickFilosofia}>
                <h1
                  className='freeDoka mobile-footer-title'
                  id='titolo-mobile3'
                >
                  <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title3')}
                </h1>
              </div>
            </li>
            <Modal
              className='mobileModal filosofia'
              visible={modalFilosofiaVisible}
              setVisible={setModalFilosofiaVisible}
              title={t('home:sectionFooter:title3')}
              closable={true}
            >
              <div
                className='modal-app mobile scrollY'
              >
                <p>
                  {t('home:sectionFooter:filosofia1')}
                </p>
                <p>
                  {t('home:sectionFooter:filosofia2')}
                </p>
                <p>
                  {t('home:sectionFooter:filosofia3')}
                </p>
                <p>
                  {t('home:sectionFooter:filosofia4')}
                </p>
              </div>
            </Modal>
            <li>
              <a href={configuration.pageLinks.press}>
                <h1
                  className='freeDoka mobile-footer-title'
                  id='titolo-mobile4'
                >
                  <ChevronRightIcon fontSize='large' /> {t('home:sectionFooter:title4')}
                </h1>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <a className='navbar-brand' href={configuration.pageLinks.home}>
            <img src={logoFooter} alt='' />
          </a>
        </div>
        <Container className="socialHomeSection" id='titolo-mobile-5'>
          <a href='https://www.linkedin.com/company/trusty-a-true-story' target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-linkedin' />
          </a>
          <a href='https://www.facebook.com/trustyItaly' target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-facebook' />
          </a>
          <a href='https://www.instagram.com/trustyofficial_/' target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-instagram' />
          </a>
          <a href='https://www.youtube.com/channel/UCC-Yqqtk-OlA_6oTsVCEX0A' target='_blank' rel='noopener noreferrer'>
            <i className='fab fa-youtube' />
          </a>
        </Container>
      </div>
    </>
  )
  // }
}

export default SectionFooter
