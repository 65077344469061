import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ominoImg from 'assets/images/home/home/omino.png'

import Modal from '../../../../../components/Modal'

import YouTube from 'react-youtube'

const SectionTop = () => {
  const { t } = useTranslation(['home'])
  const [modalSpotVisible, setModalSpotVisible] = useState(false)
  const handleClickSpot = () => setModalSpotVisible(true)

  const videoOnReady = (event) => {
    // setupListener()
  }

  // render() {
  const opts = {
    height: '400px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1
    }
  }
  return (
    <div id='top-home-mobile' className='mobile-section bg-cyan mobileSection'>
      <div className='section-top-text'>
        <h1>
          {t('home:sectionTop:descriptionFirst')} <span className='freeDoka'>{t('home:sectionTop:bold')}</span> {t('home:sectionTop:descriptionLast')}
          <span onClick={handleClickSpot} className='freeDoka showVideoDesktop' id='titoloTop'>
            {t('home:sectionTop:btnText')}
          </span>
        </h1>
        <Modal
          className='mobileModal video'
          visible={modalSpotVisible}
          setVisible={setModalSpotVisible}
          title='A True Story'
          closable={true}
        >
          <div className='modal-app mobile'>
            <YouTube
              videoId='oVnfeLefTH0'
              id='player'
              className='ytVideo'
              containerClassName='videoContainer'
              opts={opts}
              onReady={videoOnReady}
            // onPlay={func}
            // onPause={func}
            // onEnd={func}
            // onError={func}
            // onStateChange={func}
            // onPlaybackRateChange={func}
            // onPlaybackQualityChange={func}
            />
          </div>
        </Modal>
      </div>
      <div className='section-top-img'>
        <img className='img-omino' src={ominoImg} alt='' />
      </div>
    </div>
  )
}

export default SectionTop
