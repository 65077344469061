import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import RotateScreen from 'components/Rotate'
import Splash from 'components/Splash'
import ModalSignUpForm from './components/ModalSignUp'
import Modal from 'components/Modal'
// import ScreenRotation from 'components/ScreenRotation'

import configuration from './configuration'
import Home from 'views/Home'
import Press from 'views/Press'
import AboutUs from 'views/AboutUs'
import Contatti from 'views/Contatti'
import TrustyApp from 'views/TrustyApp/Desktop'
import PrivacyPolicy from 'views/PrivacyPolicy'
import ls from 'local-storage'
import smartlookClient from 'smartlook-client'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { GTag } from '@deptno/gtag-react'
import PageNotFound from 'components/PageNotFound'

import { isMobile } from 'Utils'

if (configuration.environment === 'production') {
  smartlookClient.init(configuration.smartlook.apiKey)

  ReactGA.initialize(configuration.googleAnalytics.apiKey)
  ReactGA.pageview(window.location.pathname + window.location.search)

  TagManager.initialize({ gtmId: configuration.googleTagManager.apiKey })

  window.ldfdr = window.ldfdr || {}
  const fs = document.getElementsByTagName('script')[0]
  const cs = document.createElement('script')
  cs.src = 'https://sc.lfeeder.com/lftracker_v1_bElvO73eRL6aZMqj.js'
  setTimeout(() => {
    fs.parentNode.insertBefore(cs, fs)
  }, 1)
}

const App = () => {
  const { t } = useTranslation(['signupModal'])
  const [isLoading, setIsLoading] = useState(true)
  const [signupVisible, setSignupVisible] = useState(false)

  const forceUpdate = () => {
    window.location.reload()
  }

  const handleCloseSignUp = () => setSignupVisible(false)

  // insert of Rotation component
  const [isHiden, setIsHiden] = useState('false')
  // ls.remove('first')
  // Hide splashscreen
  window.addEventListener('load', () => {
    setTimeout(() => {
      setIsLoading(false)
      var ua = navigator.userAgent || navigator.vendor || window.opera
      var isInstagram = (ua.indexOf('Instagram') > -1)
      // alert(ls.get('first'))
      if (isInstagram && ls.get('first') === null) {
        ls.set('first', 'defined')
        forceUpdate()
        // alert(ls.get('first'))
      } else {
        ls.remove('first')
      }
      // alert(ls.get('first'))
    }, 1100)

    const vh = window.innerHeight
    document.documentElement.style.setProperty('--modalVh', `${vh * 1.2}`)
  })

  // window.addEventListener('beforeunload', () => {
  //   ls.remove('first')
  //   //alert(ls.get('first'))
  // })

  window.addEventListener('resize', () => {
    const vh = window.innerHeight
    document.documentElement.style.setProperty('--modalVh', `${vh * 1.2}`)
  })

  useEffect(() => {
    console.log('isHiden vale ', isHiden)
  })
  return (
    <>
      {configuration.environment === 'production' ? <GTag id={configuration.googleAnalytics.apiKey} /> : ''}
      <RotateScreen setIsHiden={setIsHiden} />
      <Splash loading={isLoading} />
      <Modal
        className='desktopModal'
        visible={signupVisible}
        setVisible={setSignupVisible}
        title={t('title')}
      >
        <div className='modal-app desktop'>
          <ModalSignUpForm
            visible={signupVisible}
            parentCallback={setSignupVisible}
          />
        </div>
      </Modal>

      {isMobile() ? (
        <Modal
          className='mobileModal'
          visible={signupVisible}
          setVisible={setSignupVisible}
          title={t('title')}>
          <div className='modal-app mobile'>
            <ModalSignUpForm
              parentCallback={handleCloseSignUp}
            />
          </div>
        </Modal>
      ) : null}
      <Router basename={'/'}>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            render={() => <div style={{ opacity: isHiden }}>
              <Home setSignupVisible={setSignupVisible} isHiden={isHiden} />
            </div>}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/press`}
            render={() => <Press setSignupVisible={setSignupVisible} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            render={() => <AboutUs setSignupVisible={setSignupVisible} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/trusty`}
            render={() => <TrustyApp setSignupVisible={setSignupVisible} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/privacy-policy`}
            render={() => <PrivacyPolicy setSignupVisible={setSignupVisible} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contatti`}
            render={() => <Contatti setSignupVisible={setSignupVisible} />}
          />
          <Route component={PageNotFound} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/instagram`}
            render={() => <Home setSignupVisible={setSignupVisible} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </>
  )
}

export default App
