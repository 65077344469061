import React from 'react'
import PropTypes from 'prop-types'
// import { useTranslation } from 'react-i18next'
// import { makeStyles } from '@material-ui/core/styles'

// import InfoArea from 'components/InfoArea/InfoArea'

// import PinDrop from '@material-ui/icons/PinDrop'
// import Phone from '@material-ui/icons/Phone'
// import BusinessCenter from '@material-ui/icons/BusinessCenter'
import { Container } from '@material-ui/core'

import ModalContactForm from './ContactForm'

// import contactUsStyle from 'assets/jss/material-kit-pro-react/views/contactUsStyle.js'

// const useStyles = makeStyles(contactUsStyle)

const FormScreen = ({ parentCallback }) => {
  // const { t } = useTranslation(['contactForm', 'contactModal'])
  // const classes = useStyles()

  return (
    <Container alignItems='center'>
      <ModalContactForm parentCallback={parentCallback} />
    </Container>
  )
}

export default FormScreen

FormScreen.propTypes = {
  parentCallback: PropTypes.func
}
