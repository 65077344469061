import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import validate from 'validate.js'
import Axios from 'axios'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem.jsx'

import Input from 'components/Input'
import Button from 'components/Button'
import configuration from '../../configuration'
import Checkbox from '@material-ui/core/Checkbox'

// import configuration from '../../configuration'

import contactUsStyle from 'assets/jss/material-kit-pro-react/views/contactUsStyle.js'

const useStyles = makeStyles(contactUsStyle)

const ModalContactForm = ({ parentCallback }) => {
  const { t } = useTranslation('ModalContactForm')
  const classes = useStyles()

  const [checked, setChecked] = React.useState(true)
  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked)
  }

  const [formState, setFormState] = useState({
    values: {},
    touched: {}
  })

  const schema = {
    name: {
      presence: { allowEmpty: false, message: t('validations.name') }
    },
    role: {
      presence: { allowEmpty: false, message: t('validations.role') }
    },
    company: {
      presence: { allowEmpty: false, message: t('validations.company') }
    },
    message: {
      presence: { allowEmpty: false, message: t('validations.message') }
    },
    email: {
      presence: { allowEmpty: false, message: t('validations.email') },
      email: {
        message: t('validations.validEmail')
      }
    }
  }

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  const handleSubmit = async (event) => {
    event.preventDefault()

    const {
      name,
      role,
      email,
      company,
      message
    } = formState.values

    try {
      await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/email/contact`,
        headers: {
          'content-type': 'application/json'
        },
        data: {
          name,
          role,
          email,
          company,
          message,
          locale: 'it-IT'
        }
      })

      parentCallback && parentCallback('SENT')
    } catch (error) {
      console.error(error)
      parentCallback && parentCallback('ERROR')
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridItem md={6} sm={6} className='contactInput'>
          <Input
            helperText={hasError('name') ? formState.errors.name[0] : null}
            inputProps={{
              name: 'name',
              required: true,
              error: hasError('name'),
              value: formState.values.name || '',
              onChange: handleChange,
              placeholder: t('inputs.name')
            }}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem md={6} sm={6} className='contactInput'>
          <Input
            helperText={hasError('role') ? formState.errors.role[0] : null}
            inputProps={{
              name: 'role',
              required: false,
              error: hasError('role'),
              value: formState.values.role || '',
              onChange: handleChange,
              placeholder: t('inputs.role')
            }}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem md={6} sm={6} className='contactInput'>
          <Input
            helperText={hasError('email') ? formState.errors.email[0] : null}
            inputProps={{
              name: 'email',
              required: true,
              error: hasError('email'),
              value: formState.values.email || '',
              onChange: handleChange,
              placeholder: t('inputs.email')
            }}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem md={6} sm={6} className='contactInput'>
          <Input
            helperText={
              hasError('company') ? formState.errors.company[0] : null
            }
            inputProps={{
              name: 'company',
              required: true,
              error: hasError('company'),
              value: formState.values.company || '',
              onChange: handleChange,
              placeholder: t('inputs.company')
            }}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem md={12} sm={12} className='contactInput'>
          <Input
            helperText={
              hasError('message') ? formState.errors.message[0] : null
            }
            inputProps={{
              required: false,
              name: 'message',
              error: hasError('message'),
              value: formState.values.message || '',
              onChange: handleChange,
              multiline: true,
              rows: 6,
              placeholder: t('inputs.message')
            }}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
        <GridItem>
          <div className='disclaimerContainer'>
            <Checkbox
              checked= {checked}
              onChange= {handleChangeCheckbox}
              inputProps={{
                required: true,
                error: hasError('disclaimer'),
                name: 'disclaimer',
                value: formState.values.disclaimer || ''
              }}
            />
            <p>
              {t('home:sectionFooter:disclaimer')} <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
            </p>
          </div>
        </GridItem>
        <GridItem md={12} sm={12} className='contactInput'>
          <div className={classes.textCenter}>
            <Button color='primary' type='submit' disabled={!formState.isValid}>
              {t('button-text')}
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  )
}

export default ModalContactForm

ModalContactForm.propTypes = {
  parentCallback: PropTypes.func
}
