import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ModalContactForm from '../../components/ModalContact'
import { Container, Grid } from '@material-ui/core'
import './style.css'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { isMobile } from 'Utils'
import Parallax from 'components/Parallax'
import MetaTags from 'react-meta-tags'
import '../../assets/css/custom/imagehover.css'
// import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js'
import Button from 'components/Button'
import coverImg from 'assets/images/contacts/back.png'
import Modal from 'components/Modal'
// const useStyles = makeStyles(aboutUsStyle)

const Contatti = ({ setSignupVisible }) => {
  const { t } = useTranslation(['contacts'])
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    window.location.href = 'https://www.trusty.id/'
  }, [])

  const handleClick = () => setSignupVisible(true)

  const [modalVisible, setModalVisible] = useState(false)
  const handleClickContact = () => setModalVisible(true)

  const handleCloseContact = () => setModalVisible(false)
  // const classes = useStyles()
  return (<>
    <MetaTags>
      <title>{t('metaTitle')}</title>
      <meta name="description" content={t('metaDescription')} />
      <meta property="og:title" content={t('metaTitle')} />
      {/* <meta property="og:image" content="path/to/image.jpg" /> */}
    </MetaTags>
    <Header page={4} setSignupVisible={setSignupVisible} />

    {isMobile() ? (
      <Modal
        className='mobileModal'
        visible={modalVisible}
        setVisible={setModalVisible}
        title={t('contactForm:title')}
      >
        <div className='modal-app mobile scrollY'>
          <ModalContactForm
            parentCallback={handleCloseContact}
          />
        </div>
      </Modal>
    )
      : <Modal
        className='desktopModal contacts'
        visible={modalVisible}
        setVisible={setModalVisible}
        title={t('contactForm:title')}
      >
        <div className='modal-app desktop'>
          <ModalContactForm
            parentCallback={handleCloseContact}
          />
        </div>
      </Modal>
    }

    <div id='contact-us' >
      <Parallax small className='top' >

        <div className='contactFlex'>
          <div className='title-page' >
            <h1 > {t('top.title')} </h1>
            <h3 > {t('top.description')} </h3>
          </div>
          <div className='back-image-bg' >
            <img src={coverImg} alt='' className='ominoImg' />
          </div>
        </div>

      </Parallax>
      <div className='mainContact' style={{ paddingTop: '1px' }} >
        <Container className="circleContainer">
          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={6} className="boxGrid">
              <figure className="imghvr-hinge-up ih-delay-md rounded pesca">
                <div className="boxTitle">
                  <h3>{t('box1.title')}</h3>
                  <Button
                    className='btn-primary'
                    size='lg'
                    type='button'>
                    {t('button.discover')}
                  </Button>
                </div>
                <figcaption>
                  {/* <h3>{t('box1.title')}</h3> */}
                  <p>
                    {t('box1.description')} <b>{t('box1.bold')}</b> {t('box1.description2')}
                  </p>
                  <Button
                    id='provalo-intro'
                    className='btn-primary'
                    size='lg'
                    type='button'
                    onClick={handleClick}
                  >
                    {t('common:signup')}
                  </Button>
                </figcaption>
              </figure>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="boxGrid">
              <figure className="imghvr-hinge-up ih-delay-md rounded mTop verde">
                <div className="boxTitle">
                  <h3>{t('box2.title')}</h3>
                  <Button
                    className='btn-primary'
                    size='lg'
                    type='button'>
                    {t('button.discover')}
                  </Button>
                </div>
                <figcaption>
                  {/* <h3>{t('box2.title')}</h3> */}
                  <p>
                    {t('box2.description')}
                  </p>
                  <Button
                    id='provalo-intro'
                    className='btn-primary'
                    size='lg'
                    type='button'
                    onClick={handleClickContact}
                  >
                    {t('contattaci')}
                  </Button>
                </figcaption>
              </figure>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="boxGrid">
              <figure className="imghvr-hinge-up ih-delay-md rounded blu">
                <div className="boxTitle">
                  <h3>{t('box3.title')}</h3>
                  <Button
                    className='btn-primary'
                    size='lg'
                    type='button'>
                    {t('button.discover')}
                  </Button>
                </div>
                <figcaption>
                  {/* <h3>{t('box3.title')}</h3> */}
                  <p>
                    {t('box3.description')}
                  </p>
                  <Button
                    id='provalo-intro'
                    className='btn-primary'
                    size='lg'
                    type='button'
                    onClick={handleClickContact}
                  >
                    {t('contattaci')}
                  </Button>
                </figcaption>
              </figure>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="boxGrid">
              <figure className="imghvr-hinge-up ih-delay-md rounded mTop yellow">
                <div className="boxTitle">
                  <h3>{t('box4.title')}</h3>
                  <Button
                    className='btn-primary'
                    size='lg'
                    type='button'>
                    {t('button.discover')}
                  </Button>
                </div>
                <figcaption>
                  {/* <h3>{t('box4.title')}</h3> */}
                  <p>
                    {t('box4.description')}
                  </p>
                  <Button
                    id='provalo-intro'
                    className='btn-primary'
                    size='lg'
                    type='button'
                    onClick={handleClickContact}
                  >
                    {t('contattaci')}
                  </Button>
                </figcaption>
              </figure>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
    <Footer />
  </>
  )
}

export default Contatti

Contatti.propTypes = {
  setSignupVisible: PropTypes.func
}
