import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Grid, Container } from '@material-ui/core'

import logo1Black from 'assets/images/press/logo1-black.png'
import logo1White from 'assets/images/press/logo1-white.png'
import logo2Black from 'assets/images/press/logo2-black.png'
import logo2White from 'assets/images/press/logo2-white.png'

import colorsImg from 'assets/images/press/colors.svg'

const SectionBrand = () => {
  const { t } = useTranslation(['press'])
  return (
    <>
      <Container>
        <section>
          <h2><b>{t('brand.mainTitle')}</b></h2>
          <h2>{t('brand.title1')}</h2>
          <h4>{t('brand.description1')}</h4>
        </section>
        <section>
          <h2>{t('brand.title2')}</h2>
          <h4>{t('brand.description2')}</h4>
        </section>
      </Container>
      <section className='large bg-grey'>
        <Container>
          <h2>{t('brand.title3')}</h2>
          <h4>{t('brand.description3')}</h4>
          <Container maxWidth='md'>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <div className='press-logo bg-white'>
                  <img src={logo2Black} alt='' />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className='press-logo bg-white'>
                  <img src={logo1Black} alt='' />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className='press-logo bg-darkblue'>
                  <img src={logo2White} alt='' />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className='press-logo bg-darkblue'>
                  <img src={logo1White} alt='' />
                </div>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </section>
      <Container>
        <section>
          <h2>{t('brand.title4')}</h2>
          <h4>{t('brand.description4')}</h4>
          <br />
          <Container maxWidth='md'>
            <img src={colorsImg} alt='' />
          </Container>
        </section>
        <section>
          <h2>{t('brand.title5')}</h2>
          <h4>
            <Trans i18nKey='press:brand.description5'>
              You can download <a href='!#'>PNG</a> files. Please don’t alter
              the Trusty logo.
            </Trans>
          </h4>
        </section>
      </Container>
    </>
  )
}

export default SectionBrand
