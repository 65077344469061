import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import FormScreen from './FormScreen'
import SentScreen from './SentScreen'
import ErrorScreen from './ErrorScreen'

const WhitePaper = ({ parentCallback }) => {
  const [sentStatus, setSentStatus] = useState('INIT')

  useEffect(() => {
    if (sentStatus === 'SENT') {
      setTimeout(() => {
        if (parentCallback) parentCallback(false)
      }, 10000)
    }
  }, [sentStatus, setSentStatus, parentCallback])

  return (
    <>
      {
        {
          INIT: <FormScreen parentCallback={setSentStatus} />,
          SENT: <SentScreen />,
          ERROR: <ErrorScreen />
        }[sentStatus]
      }
    </>
  )
}

export default WhitePaper

WhitePaper.propTypes = {
  parentCallback: PropTypes.func
}
