import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'

import './style.css'
import MetaTags from 'react-meta-tags'

import SectionIntro from './Sections/SectionIntro'
import SectionCards from './Sections/SectionCards'
import SectionTop from './Sections/SectionTop'
// import SectionPricing from './Sections/SectionPricing'
import SectionFooter from './Sections/SectionFooter'
import { MoveScroll } from 'Utils'

const moveScroll = new MoveScroll()
let firstWheel = 0
let scrollStopped = null
const listenerAnimation = function (event) {
  console.log('wheel')
  if (firstWheel === 0 && document.getElementById('timeline')) {
    clearTimeout(scrollStopped)
    scrollStopped = setTimeout(() => {
      firstWheel = 1
      console.log('ho esaurito la spinta, lanccio animazione setAnimation!')
      moveScroll.setAnimation('timeline', event.deltaY)
    }, 30)
  } else {
    // console.log('non intervengo')
  }
}

// window.addEventListener('touchmove', (e) => { listenerAnimation(e) })
window.addEventListener('wheel', (e) => { listenerAnimation(e) })

const listener = setInterval(() => {
  if (document.getElementById('howItWork')) {
    clearInterval(listener)
    document.getElementById('howItWork').addEventListener('click', () => {
      moveScroll.setAnimation('timeline')
    })
  }
}, 10)

const DesktopVersion = ({ setSignupVisible }) => {
  const { t } = useTranslation(['home'])
  // moveScroll.addListener()

  return (
    <>
      <MetaTags>
        <title>{t('metaTitle')}</title>
        <meta name="description" content={t('metaDescription')} />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('metaTitle')} />
        <meta property="og:description" content={t('metaDescription')} />
        <meta property="og:url" content="https://trusty.id/" />
        <meta property="og:site_name" content="Trusty" />
        <meta property="og:image" content="https://trusty.id/static/media/omino.a85251ec.png" />
        {/* <meta property="fb:app_id" content="750031022251808"/> */}
      </MetaTags>
      <SectionIntro setSignupVisible={setSignupVisible} />
      <div id='home'>
        <SectionTop />
        <div id='card-how'>
          <div>
            <h1 className='freeDoka' style={{ fontSize: '7rem' }}>
              {t('home:sectionHow:title')}
            </h1>
          </div>
        </div>
        <SectionCards />
        <div id='home-qr' className='bg-white mainContainer'>
          <div className='home-qr-content'>
            <Container maxWidth='lg'>
              <h1>
                {t('home:sectionQr:title')}
              </h1>
            </Container>
          </div>
        </div>
        <div
          style={{
            witdh: '100vw',
            height: '100vh',
            zIndex: '-2',
            position: 'relative'
          }}
        ></div>
        {/* <SectionPricing /> */}
        <SectionFooter />
      </div>
    </>
  )
}

export default DesktopVersion

DesktopVersion.propTypes = {
  setSignupVisible: PropTypes.func
}
