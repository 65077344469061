import { TweenMax, Linear } from 'gsap'
import { perc, DOMelement } from 'Utils'

export const get = (funcs) => {
  const { setRightHidden } = funcs

  const HEADER_SIZE = 99
  const vh = window.innerHeight * 1.1

  return [
    // ---------------- HOME FIX --------------------------------------------------
    {
      scene: {
        triggerElement: '#home',
        offset: 0,
        duration: (vh * 1) + 'px'
      },
      debug: { name: 'home fix' },
      setPin: true
    },
    // Qr scale
    {
      scene: {
        triggerElement: '#timeline',
        offset: 0,
        duration: (vh * 0.2) + 'px'
      },
      debug: { name: 'scale' },
      tween: TweenMax.to('.qr-code img', 1, {
        scale: 30,
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 10),
        duration: (vh * 0.22) + 'px'
      },
      debug: { name: 'hide qr', indent: 120 },
      tween: TweenMax.to('.qr-code img', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // ---------------- FADE OUT -------------------------------------------------
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 5),
        duration: (vh * 0.10) + 'px'
      },
      debug: { name: 'home hide', indent: 210 },
      tween: TweenMax.to(
        '#intro-products, #intro-text, #intro-scaffali, .qr-border',
        1,
        {
          opacity: 0,
          ease: Linear.easeNone
        }
      )
    },
    // ---------------- ZOOM -----------------------------------------------------
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 20),
        duration: (vh * 0.8) + 'px'
      },
      debug: { name: 'zoom' },
      tween: TweenMax.to('#intro', 1, {
        scale: 6,
        x: '-110vh',
        ease: Linear.easeNone
      }),
      sceneEvent: [
        {
          type: 'end',
          func: (event) => {
            if (event.scrollDirection === 'FORWARD') {
              DOMelement.get('#intro').item(0).style.display = 'none'
              setRightHidden(false)
            } else if (event.scrollDirection === 'REVERSE') {
              DOMelement.get('#intro').item(0).style.display = 'initial'
              setRightHidden(true)
            }
          }
        }
      ]
    },
    // ---------------- TEXT FADE IN ----------------------------------------------
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 60),
        duration: (vh * 0.5) + 'px'
      },
      debug: { name: 'text opacity' },
      tween: TweenMax.to('#top-home h1', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // ---------------- HEADER BG ---------------------------------------------------
    // Header bg IN
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 100),
        duration: 5
      },
      debug: { name: 'show header', indent: 120 },
      tween: TweenMax.to('#header-bg-sky', 1, {
        height: HEADER_SIZE + 'px',
        ease: Linear.easeNone
      })
    },
    // Header bg OUT
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 100) + perc('top-home', 80) - HEADER_SIZE,
        duration: HEADER_SIZE
      },
      debug: { name: 'hide header' },
      tween: TweenMax.to('#header-bg-sky', 1, {
        height: 0,
        ease: Linear.easeNone
      })
    },
    // Text Come funziona
    {
      scene: {
        triggerElement: '#card-how',
        offset: 0,
        duration: (vh * 3) + 'px'
      },
      debug: { name: 'pin & scale text', indent: 100 },
      tween: TweenMax.to('#card-how div', 1, {
        scale: 50,
        ease: Linear.easeNone
      }),
      sceneMethods: {
        setPin: {
          element: '#card-how',
          settings: { pushFollowers: true }
        }
      }
    },
    // {
    //   scene: {
    //     triggerElement: '#card-how',
    //     offset: 0,
    //     duration: (vh * 1.817777) + 'px'
    //   },
    //   debug: { name: 'pin schede' },
    //   sceneMethods: {
    //     setPin: {
    //       element: '#home-schede',
    //       settings: { pushFollowers: true }
    //     }
    //   }
    // },
    {
      scene: {
        triggerElement: '#card-how',
        offset: perc('#card-how', 30),
        duration: (vh * 0.1) + 'px'
      },
      debug: { name: 'opacity schede' },
      tween: TweenMax.to('#home-schede', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#card-how',
        offset: perc('#card-how', 60),
        duration: (vh * 0.30) + 'px'
      },
      debug: { name: 'opacity text' },
      tween: TweenMax.to('#card-how div', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // ------------  Schede -----------------------
    {
      scene: {
        triggerElement: '#scheda-1',
        offset: 0,
        // offset: perc('#scheda-1', 200) + 3,
        duration: (vh * 2) + 'px'
      },
      debug: { name: 'SCHEDA1', indent: 320 },
      sceneMethods: {
        setPin: {
          element: '#scheda-1',
          settings: { pushFollowers: false }
        }
      }
    },
    // {
    //   scene: {
    //     triggerElement: '#scheda-1',
    //     offset: 0,
    //     // offset: perc('#scheda-1', 200) + 3,
    //     duration: (vh * 2) + 'px'
    //   },
    //   debug: { name: 'SCHEDA1', indent: 120 },
    //   sceneMethods: {
    //     setPin: {
    //       element: '#scheda-1',
    //       settings: { pushFollowers: false }
    //     }
    //   }
    // },
    {
      scene: {
        triggerElement: '#scheda-2',
        offset: 0,
        duration: (vh * 1) + 'px'
      },
      sceneMethods: {
        setPin: {
          element: '#scheda-2',
          settings: { pushFollowers: false }
        }
      }
    },
    {
      scene: {
        triggerElement: '#scheda-3',
        offset: 0,
        duration: '100%'
      },
      sceneMethods: {
        setPin: {
          element: '#scheda-3',
          settings: { pushFollowers: false }
        }
      }
    },
    {
      scene: {
        triggerElement: '#scheda-4',
        offset: 0,
        duration: '100%'
      },
      sceneMethods: {
        setPin: {
          element: '#scheda-4',
          settings: { pushFollowers: false }
        }
      }
    },
    // Header bg color
    {
      scene: {
        triggerElement: '#scheda-5',
        offset: 0,
        duration: perc('#scheda-5', 100) - HEADER_SIZE
      },
      debug: { name: 'header orange', indent: 120 },
      sceneEvent: [
        {
          type: 'enter',
          func: (event) =>
            (DOMelement.get('#header-bg-orange').item(0).style.opacity = 1)
        },
        {
          type: 'leave',
          func: (event) =>
            (DOMelement.get('#header-bg-orange').item(0).style.opacity = 0)
        }
      ]
    },
    // ------------  QR -----------------------
    {
      scene: {
        triggerElement: '#scheda-5',
        offset: 0,
        duration: '200%'
      },
      debug: { name: 'SCHEDA5', indent: 120 },
      sceneMethods: {
        setPin: {
          element: '#home-qr',
          settings: { pushFollowers: false }
        }
      }
    },
    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: '-100vh',
        duration: '25%'
      },
      debug: { name: 'OPACITY QR', indent: 120 },
      tween: TweenMax.to('.home-qr-content', 1, {
        scale: 30,
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: '-100vh',
        duration: '15%'
      },
      debug: { name: 'OPACITY QR', indent: 120 },
      tween: TweenMax.to('.home-qr-content', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // --------------- VITTORIO --------------
    {
      scene: {
        triggerElement: '#scheda-pricing', // innesco
        offset: 0, // distanza da coordinata 0 (profondità scroll)
        duration: '200%' // durata dell'animazione misurata in profondità di scroll
      },
      sceneMethods: {
        setPin: {
          element: '#scheda-pricing',
          settings: { pushFollowers: false }
        }
      },
      debug: { name: 'pricing' }
    },
    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: perc('#scheda-pricing', 30),
        duration: '350%'
      },
      sceneMethods: {
        setPin: {
          element: '#scheda-footer',
          settings: { pushFollowers: false }
        }
      },
      debug: { name: 'footer' }
    },

    {
      scene: {
        triggerElement: '#scheda-footer',
        offset: perc('#scheda-footer', 70),
        duration: '50%'
      },
      debug: { name: 'hidden pricing', indent: 120 },
      tween: TweenMax.to('#scheda-pricing', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },

    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: perc('#scheda-pricing', 120),
        duration: '80%'
      },
      debug: { name: 'footer', indent: 120 },
      tween: TweenMax.to('#scheda-footer', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- PRICING 1 BORDER EFFECT  --------------
    {
      scene: {
        triggerElement: '#scheda-footer',
        offset: perc('#scheda-footer', 0),
        duration: '100%'
      },
      debug: { name: 'border-effect', indent: 120 },
      tween: TweenMax.to('.hightlight', 1, {
        backgroundPosition: '400px',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-footer',
        offset: perc('#scheda-footer', 20),
        duration: '20%'
      },
      debug: { name: 'border-show' },
      tween: TweenMax.to('.hightlight', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- PRICING 1 --------------
    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: perc('#scheda-pricing', 2),
        duration: '30%'
      },
      debug: { name: 'scheda prezzo 1' },
      tween: TweenMax.to('#pricing1', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- PRICING 2 --------------
    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: perc('#scheda-pricing', 15),
        duration: '30%'
      },
      debug: { name: 'scheda prezzo 2' },
      tween: TweenMax.to('#pricing2', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- PRICING 3 --------------
    {
      scene: {
        triggerElement: '#scheda-pricing',
        offset: perc('#scheda-pricing', 20),
        duration: '30%'
      },
      debug: { name: 'scheda prezzo 3' },
      tween: TweenMax.to('#pricing3', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 1 --------------
    {
      scene: {
        triggerElement: '#empty-div',
        offset: perc('#empty-div', 30),
        duration: '30%'
      },
      debug: { name: 'titolo1' },
      tween: TweenMax.to('#titolo1', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 2 --------------
    {
      scene: {
        triggerElement: '#empty-div',
        offset: perc('#empty-div', 40),
        duration: '30%'
      },
      debug: { name: 'titolo2' },
      tween: TweenMax.to('#titolo2', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 3 --------------
    {
      scene: {
        triggerElement: '#empty-div',
        offset: perc('#empty-div', 50),
        duration: '30%'
      },
      debug: { name: 'titolo3' },
      tween: TweenMax.to('#titolo3', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 4 --------------
    {
      scene: {
        triggerElement: '#empty-div',
        offset: perc('#empty-div', 60),
        duration: '30%'
      },
      debug: { name: 'titolo4' },
      tween: TweenMax.to('#titolo4', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 5 --------------
    {
      scene: {
        triggerElement: '#empty-div',
        offset: perc('#empty-div', 70),
        duration: '30%'
      },
      debug: { name: 'titolo5' },
      tween: TweenMax.to('#titolo5', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    }
  ]
}
