const manifest = require('../package.json')
const frontendBaseUrl = process.env.REACT_APP_FRONTEND_BASE_URL || ''
const configuration = {
  version: manifest.version,
  environment: process.env.REACT_APP_ENV,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  contactApiUrl:
    process.env.REACT_APP_CONTACT_API_URL || 'http://localhost:5000/send',
  pageLinks: {
    home: frontendBaseUrl + '/',
    press: frontendBaseUrl + '/press',
    aboutUs: frontendBaseUrl + '/about',
    trustyApp: frontendBaseUrl + '/trusty',
    privacyPolicy: frontendBaseUrl + '/privacy-policy',
    contatti: frontendBaseUrl + '/contatti'
  },
  smartlook: {
    apiKey: 'e003af4e16084b168e9e03b3b8e3784ce636e79c'
  },
  googleAnalytics: {
    apiKey: 'UA-56363749-7'
  },
  googleTagManager: {
    apiKey: 'GTM-WGC7KRB'
  }
}

export default configuration
