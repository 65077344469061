import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import Card from 'components/Card/Card'
import Button from 'components/Button'

import Close from '@material-ui/icons/Close'

import style from 'assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js'

const useStyles = makeStyles(style)

const Modal = (props) => {
  const { visible, setVisible, children, closable, title, ...rest } = props

  const classes = useStyles()
  const handleClose = () => setVisible(false)

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + ' ' + classes.modalSignup
      }}
      open={visible}
      onClose={handleClose}
      disableBackdropClick={closable === false}
      disableEscapeKeyDown={closable === false}
      {...rest}
    >
      <Card plain className={classes.modalSignupCard}>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <h2 className='modal-title' style={{ marginTop: '0', width: '100%' }}>
            {title}
          </h2>
          {closable !== false ? (
            <>
              <Button
                simple
                className={classes.modalCloseButton}
                key='close'
                aria-label='Close'
                onClick={handleClose}
                style={{ position: 'absolute', top: '6%', right: '5%' }}
              >
                <Close className={classes.modalClose} />
              </Button>
            </>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.modalBody}>{children}</DialogContent>
      </Card>
    </Dialog>
  )
}

export default Modal

Modal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string,
  closable: PropTypes.bool
}
