import * as ScrollMagic from 'scrollmagic'
import { TweenMax } from 'gsap'

import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'
import { ScrollMagicPluginIndicator } from 'scrollmagic-plugins'

ScrollMagicPluginIndicator(ScrollMagic)
ScrollMagicPluginGsap(ScrollMagic, TweenMax)

/* ------------------------------- DEBUG MODE ------------------------------- */
const DEBUG_MODE = false
// const DEBUG_MODE = true
/* -------------------------------------------------------------------------- */

// class Animation {
//   constructor(){

//   }

//   load(){

//   }

// }

const Animations = {
  // ScrollMagic Controller
  controller: new ScrollMagic.Controller({
    addIndicators: DEBUG_MODE
  }),
  scrollScene: [],
  /**
   * Append scenes to ScrollMagic Controller
   * @param {*} scenes Array of scene
   */
  load: function (scenes) {
    scenes.forEach((element, index) => {
      const { scene, tween, sceneEvent, sceneMethods, debug, setPin } = element

      // Set triggerHook default value to 0
      if (scene.triggerHook === undefined) scene.triggerHook = 0

      // ScrollMagic Scene instance
      const scrollScene = new ScrollMagic.Scene(scene)

      // Add pin to Scene
      if (setPin) {
        scrollScene.setPin(scene.triggerElement)
      } else if (sceneMethods && sceneMethods.setPin) {
        scrollScene.setPin(
          sceneMethods.setPin.element,
          sceneMethods.setPin.settings
        )
      }

      // Add events to Scene
      if (sceneEvent) {
        sceneEvent.forEach((event) => scrollScene.on(event.type, event.func))
      }

      // Add tween to Scene
      if (tween) scrollScene.setTween(tween)

      // debug indicator label
      if (DEBUG_MODE && debug) scrollScene.addIndicators(debug)

      // Add Scene to controller
      this.scrollScene[index] = scrollScene
      this.controller.addScene(scrollScene)
    })
  }
}

export default Animations
