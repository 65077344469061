import React from 'react'
import PropTypes from 'prop-types'
import configuration from '../../configuration'
import { isMobile, isTablet } from 'Utils'
import classNames from 'classnames'
import HeaderStructure from './HeaderStructure'
import Dropdown from 'components/Dropdown'
import Button from 'components/Button'
import HowItWork from 'components/Header/HowItWork'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem } from '@material-ui/core'

import navbarsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js'
import Language from '@material-ui/icons/Language'

const useStyles = makeStyles(navbarsStyle)

const Header = ({ scroll, page, rightHidden, setSignupVisible }) => {
  const classes = useStyles()
  // const { t } = useTranslation(['home, common'])
  const { i18n, t } = useTranslation(['home, common'])

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang, (err, t) => {
      if (err) return console.log('something went wrong loading', err)
      t('key')
    })
  }

  const onScroll =
    scroll === undefined || scroll === true
      ? { changeColorOnScroll: { height: 100, color: 'white' } }
      : null

  const linkClass = {
    press:
      page === 2
        ? classes.navLink + ' ' + classes.navLinkActive
        : classes.navLink,
    aboutUs:
      page === 3
        ? classes.navLink + ' ' + classes.navLinkActive
        : classes.navLink,
    contatti:
      page === 4
        ? classes.navLink + ' ' + classes.navLinkActive
        : classes.navLink
  }

  return (
    <HeaderStructure
      color='transparent'
      fixed={true}
      rightHidden={rightHidden}
      setSignupVisible={setSignupVisible}
      {...onScroll}
      links={
        <List
          className={classes.list + ' ' + classes.mrAuto + ' link-menu'}
          style={{ width: '100%' }}
        >
          {isMobile() || isTablet()
            ? (
              <>
                <ListItem className={classes.listItem}>
                  <Button
                    href={configuration.pageLinks.home}
                    className={linkClass.home}
                    color='transparent'
                  >
                    Home
                  </Button>
                </ListItem>
              </>
            )
            : null}

          <div
            style={{
              display: !rightHidden ? 'none' : 'block'
            }}
          >
            <ListItem className={classes.listItem} id='buttonHowItWork'>
              <Button
                className={classNames(linkClass.press, 'menu-list')}
                color='transparent'
              >

                <HowItWork />
              </Button>
            </ListItem>
          </div>
          <ListItem className={classes.listItem}>
            <Button
              href={configuration.pageLinks.aboutUs}
              className={linkClass.aboutUs}
              color='transparent'
            >
              About us
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href={configuration.pageLinks.press}
              className={linkClass.press}
              color='transparent'
            >
              Press
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              href={configuration.pageLinks.contatti}
              className={linkClass.contatti}
              color='transparent'
            >
              {t('common:contatti')}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Dropdown
              dropdownHeader='Language'
              buttonProps={{
                className: classes.navLink,
                color: 'transparent'
              }}
              buttonIcon={Language}
              dropdownList={[
                {
                  label: 'ITA',
                  onClick: () => changeLanguage('it-IT')
                },
                {
                  label: 'EN',
                  onClick: () => changeLanguage('en-EN')
                },
                {
                  label: 'FRA',
                  onClick: () => changeLanguage('fr-FR')
                }
              ]}
            />
          </ListItem>
          <div
            style={{
              opacity: rightHidden ? '0' : '1'
            }}
            className='right'
          >
            <ListItem className={classes.listItem}>
              <Button
                id='provalo-header'
                className={classes.navLink + ' btn-primary'}
                onClick={() => setSignupVisible(true)}
              >
                {t('common:signup')}
              </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                id='login-header'
                className={classes.navLink + ' btn-secondary'}
                href='https://app.trusty.id/'
                type='button'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('login')}
              </Button>
            </ListItem>
          </div>
        </List >
      }
    />
  )
}

export default Header

Header.defaultProp = {
  scroll: true,
  page: 0
}

Header.propTypes = {
  scroll: PropTypes.bool,
  rightHidden: PropTypes.bool,
  page: PropTypes.number,
  setSignupVisible: PropTypes.func
}
