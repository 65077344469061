import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'Utils'
import logoImg from 'assets/images/loghi/trusty-logo-black.svg'

let firstLoadListenerOrientation = 0

const RotateScreen = ({ setIsHiden }) => {
  useEffect(() => {
    return () => {
      applyInvitationRotate('load')
      setListener()
    }
  })
  console.log('load rotate!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
  const getDeviceType = () => {
    const ua = navigator.userAgent
    // console.log('getDeviceType', ua)
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  const readDeviceOrientation = () => {
    // console.log('orientation', window.orientation)
    if ((window.orientation) === 90) {
      // Landscape DESTRA
      return 1
    } else if ((window.orientation) === -90) {
      // Landscape SINISTRA
      return 2
    } else {
      // PORTARTE
      return 0
    }
  }

  const applyInvitationRotate = (event) => {
    const disclaimer = document.getElementById('innerDisclaimer')
    const logo = document.getElementById('animatedLogo')
    console.log('---------------- applyInvitationRotate ---------------- ')
    console.log('readDeviceOrientation ', readDeviceOrientation())
    console.log('getDeviceType ', getDeviceType())
    console.log('firstLoadListenerOrientation ', firstLoadListenerOrientation)
    console.log('event ', event)
    if (getDeviceType() === 'tablet' || getDeviceType() === 'desktop') {
      console.log('Is tablet or desktop')
      if (firstLoadListenerOrientation > 1) {
        console.log('firstLoad')
        window.location.reload()
      }
    } else if (
      readDeviceOrientation() === 1 && getDeviceType() === 'mobile') {
      setIsHiden('0')
      console.log('Is mobile gira dx')
      disclaimer.innerHTML = 'sinistra!'
      logo.classList.add('animationSx')
      logo.classList.remove('animationDx')
    } else if (
      readDeviceOrientation() === 2 && getDeviceType() === 'mobile') {
      setIsHiden('0')
      console.log('Is mobile gira sx')
      disclaimer.innerHTML = 'destra!'
      logo.classList.add('animationDx')
      logo.classList.remove('animationSx')
    } else if (
      readDeviceOrientation() === 0 && getDeviceType() === 'mobile' && firstLoadListenerOrientation === 2 && event !== 'load') {
      console.log('Portrate')
      window.location.reload()
    } else {
      console.log('applyInvitationRotate return false')
      setIsHiden('1')
      console.log('Is mobile giusto')
    }
  }

  const setListener = () => {
    if (firstLoadListenerOrientation === 0) {
      firstLoadListenerOrientation = 1
      window.addEventListener(
        'orientationchange',
        () => {
          firstLoadListenerOrientation++
          console.log('event orientation')
          applyInvitationRotate()
        },
        false
      )
    }
  }

  return (
    isMobile() ? (
      <div id='rotation' className='fixedContainer'>
        <img className='animatedLogo' id='animatedLogo' alt='' src={logoImg} />
        <h2 id='disclaimer' className='rotationDisclaimer'>Ruota verso <span id='innerDisclaimer'></span></h2>
      </div>) : null)
}

export default RotateScreen

RotateScreen.propTypes = {
  setIsHiden: PropTypes.func
}
