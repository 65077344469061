import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import ominoImg from 'assets/images/home/home/omino.png'
import Modal from '../../../../../components/Modal'

import YouTube from 'react-youtube'

const SectionTop = () => {
  const { t } = useTranslation(['home'])
  const [modalSpotVisible, setModalSpotVisible] = useState(false)
  const handleClickSpot = () => setModalSpotVisible(true)

  const videoOnReady = (event) => {
    // setupListener()
  }

  // render() {
  const opts = {
    height: '400px',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1
    }
  }

  return (
    <div id='top-home'>
      <div id='block-1'>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        >
          <Grid container alignItems='center'>
            <Grid item xs={12} sm={6}>
              <h1>
                {t('home:sectionTop:descriptionFirst')} <span className='freeDoka'>{t('home:sectionTop:bold')}</span> {t('home:sectionTop:descriptionLast')}
                <span onClick={handleClickSpot} className='freeDoka showVideoDesktop' id='titoloTop'>
                  {t('home:sectionTop:btnText')}
                </span>
              </h1>

              <Modal
                className='desktopModal video'
                visible={modalSpotVisible}
                setVisible={setModalSpotVisible}
                title='A True Story'
                closable={true}
              >
                <div className='modal-app desktop'>
                  <YouTube
                    videoId='oVnfeLefTH0'
                    id='player'
                    className='ytVideo'
                    containerClassName='videoContainer'
                    opts={opts}
                    onReady={videoOnReady}
                  // onPlay={func}
                  // onPause={func}
                  // onEnd={func}
                  // onError={func}
                  // onStateChange={func}
                  // onPlaybackRateChange={func}
                  // onPlaybackQualityChange={func}
                  />
                </div>
              </Modal>
            </Grid>
            <Grid item xs={12} sm={6}>
              <img src={ominoImg} alt='' />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default SectionTop
