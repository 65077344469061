import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// import ls from 'local-storage'
import validate from 'validate.js'
import Axios from 'axios'

import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/Button'
import Input from 'components/Input'
import style from 'assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js'

import Checkbox from '@material-ui/core/Checkbox'
import configuration from '../../configuration'

const useStyles = makeStyles(style)

const ModalWhitePaper = ({ parentCallback }) => {
  const classes = useStyles()
  const { t } = useTranslation(['signupForm'])

  const [checked, setChecked] = React.useState(true)
  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked)
  }

  const [formState, setFormState] = useState({
    values: {},
    errors: {},
    touched: {}
  })

  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('validations.email') },
      email: {
        message: t('validations.validEmail')
      }
    }
  }

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email } = formState.values
    // const currentLanguage = ls.get('i18nextLng')

    try {
      await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/email/whitePaper`,
        headers: {
          'content-type': 'application/json'
        },
        data: {
          email,
          locale: 'it-IT'
        }
      })
      parentCallback && parentCallback('SENT')
    } catch (error) {
      console.error(error)
      parentCallback && parentCallback('ERROR')
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Input
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        helperText={hasError('email') ? formState.errors.email[0] : null}
        inputProps={{
          error: hasError('email'),
          placeholder: 'Email',
          name: 'email',
          required: true,
          onChange: handleChange,
          value: formState.values.email || ''
        }}
      />
      <div className='disclaimerContainer'>
        <Checkbox
          checked={checked}
          onChange={handleChangeCheckbox}
          inputProps={{
            required: true,
            error: hasError('disclaimer'),
            name: 'disclaimer',
            value: formState.values.disclaimer || ''
          }}
        />
        <p>
          {t('home:sectionFooter:disclaimer')} <a href='/privacy-policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
        </p>
      </div>
      <div className={classes.textCenter}>
        <Button color='primary' type='submit' disabled={!formState.isValid}>
          {t('home:sectionFooter:btnWhite')}
        </Button>
      </div>
    </form>
  )
}

export default ModalWhitePaper

ModalWhitePaper.propTypes = {
  // changeStatusCode: PropTypes.func
  parentCallback: PropTypes.func
}
