import React from 'react'

import { Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import bottigliaImg from 'assets/images/home/mobile/bottiglia.png'
import qrBorderImg from 'assets/images/home/mobile/border.png'
import qrcodeImg from 'assets/images/home/mobile/qr-code.svg'

const SectionIntro = () => {
  const { t } = useTranslation(['sectionIntroMobile'])
  return (
    <div id='intro-mobile' className='mobileSection'>
      <div id='intro-mobile-text'>
        <Container>
          <h1 className='freeDoka'>
            {t('home:sectionIntroMobile:title1')}
            <br />
            {t('home:sectionIntroMobile:title2')}
            <br />
            {t('home:sectionIntroMobile:title3')}
          </h1>
        </Container>
      </div>
      <div id='intro-mobile-bottiglia'>
        <img
          id='bottigliaOlio'
          src={bottigliaImg}
          style={{ width: '100vw', height: 'auto' }}
          alt=''
        />

        <div id='intro-mobile-total-qr'>
          <div className='intro-mobile-qr-img'>
            <img className='intro-mobile-qr' src={qrcodeImg} alt='' />
          </div>

          <div className='intro-mobile-qr-camera'>
            <img className='intro-mobile-qr-border' src={qrBorderImg} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionIntro
