import React from 'react'
import { useTranslation } from 'react-i18next'

import sentImg from 'assets/images/contact-modal/sent.svg'

const SentScreen = () => {
  const { t } = useTranslation('contactModal')
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{t('feedback-sent-text')}</h3>
      <br />
      <img style={{ width: '50%' }} src={sentImg} alt='' />
    </div>
  )
}

export default SentScreen
