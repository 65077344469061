import React from 'react'
import { Grid, Container } from '@material-ui/core'

const articoli = [
  {
    logo: require('assets/images/loghi-articoli/correire-della-sera.png'),
    href:
      'https://corriereinnovazione.corriere.it/2020/08/04/filiere-agroalimentari-rilanciano-made-italy-grazie-blockchain-918b23ec-d642-11ea-b09b-c444f41468ab.shtml'
  },
  {
    logo: require('assets/images/loghi-articoli/gambero.rosso.png'),
    href:
      'https://www.gamberorosso.it/notizie/trusty-la-convivenza-tra-blockchain-e-produzione-agroalimentare-nella-certificazione-di-apio'
  },
  {
    logo: require('assets/images/loghi-articoli/sole-24-ore.png'),
    href: 'https://www.ilsole24ore.com/art/la-blockchain-aperta-apio-tutela-specialita-made-italy-ADl3E98'
  },
  {
    logo: require('assets/images/loghi-articoli/la-nazione.png'),
    href:
      'https://www.lanazione.it/empoli/cronaca/var-group-guarda-alla-blockchain-la-societ%C3%A0-acquisisce-il-10-di-apio-1.5389824'
  },
  {
    logo: require('assets/images/loghi-articoli/distribuzione-moderna.png'),
    href:
      'https://distribuzionemoderna.info/news/var-group-sigla-una-partnership-con-apio-investe-nella-blockchain'
  },
  {
    logo: require('assets/images/loghi-articoli/abruzzo-magazine.png'),
    href: 'https://www.abruzzomagazine.it/trusty-apio-blockchain-food-qualita/'
  },
  {
    logo: require('assets/images/loghi-articoli/il-tempo.png'),
    href:
      'https://www.iltempo.it/adnkronos/2020/12/24/news/agroalimentare-startup-apio-lancia-trusty-piattaforma-blockchain-25660376/ '
  },
  {
    logo: require('assets/images/loghi-articoli/food-affair.png'),
    href:
      'https://www.foodaffairs.it/2020/11/19/nasce-trusty-la-piattaforma-blockchain-democratica-che-racconta-la-storia-dei-prodotti-sulle-nostre-tavole/ '
  },
  {
    logo: require('assets/images/loghi-articoli/pmi.png'),
    href: 'https://www.pmi.it/tecnologia/prodotti-e-servizi-ict/347066/blockchain-per-il-food-soluzioni-e-vantaggi.html '
  }
]

const SectionArticoli = () => {
  return (
    <Container>
      <Grid container spacing={1} style={{ textAlign: 'center' }}>
        {articoli.map((item) => (
          <Grid item xs={12} sm={4} key={item.href}>
            <div className='journal-logo'>
              <a style={{ width: '100%' }} href={item.href} target='_blank' rel='noopener noreferrer'>
                <img style={{ width: '100%' }} witdh="100%" src={item.logo} alt='' />
              </a>
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default SectionArticoli
