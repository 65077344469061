import { isMobileOnly } from 'react-device-detect'

/**
 * Ritorna true se il sito è navigato da dispositivo mobile.
 */

export const isTablet = () =>
  (isTablet && window.innerWidth < window.innerHeight)

export const isMobile = () =>
  isMobileOnly
// || (isTablet && window.innerWidth < window.innerHeight)

/**
 * Ritorna l'altezza % di un elemento del DOM in px.
 * @param {String} id Id elemento
 * @param {Number} percent Percentuale elemento
 */
export const perc = (id, percent) => {
  id = id.trim().replace('#', '')
  const elementHeight = document.getElementById(id).clientHeight

  if (!elementHeight) {
    throw new Error('Element not found')
  }

  return (percent * elementHeight) / 100
}

/**
 * Converte vh in px
 * @param {Number} v Valore vh da convertire in px
 */
export const vh = (v) => {
  var h = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )
  return (v * h) / 100
}

/**
 * Converte vw in px
 * @param {Number} v Valore vw da convertire in px
 */
export const vw = (v) => {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (v * w) / 100
}

export const scrollDriven = {
  _func: function (e) {
    e.preventDefault()
    e.stopPropagation()
    e.stopImmediatePropagation()
  },
  /**
   * Blocca lo scroll per un certo numero di millisecondi.
   * Se non specificati, lo scroll viene bloccato fino alla chiamata di
   * unlock.
   * @param {Number} time Numero millisecondi
   */
  lock: function (time = null) {
    window.addEventListener('wheel', this._func)
    if (time) {
      setTimeout(() => this.unlock(), time)
    }
  },
  /**
   * Sblocca lo scroll.
   */
  unlock: function () {
    window.removeEventListener('wheel', this._func)
  }
}

export const DOMelement = {
  /**
   * Ritorna un insieme di oggetti del DOM.
   * (Alias per querySelectorAll)
   * @param {String} el Oggetto da ritornare (css selectors)
   */
  get: function (el) {
    const element = document.querySelectorAll(el)
    if (!element.length) {
      throw new Error('Element ' + el + ' not found')
    }
    return element
  },
  /**
   * Nasconde una lista di oggetti del DOM.
   * (Alias per CSS opacity: 0)
   * @param  {...String} args Elementi da nascondere (css selectors)
   */
  hide: function (...args) {
    args.forEach((arg) => {
      const elements = this.get(arg)
      elements.forEach((element) => (element.style.opacity = 0))
    })
  },
  /**
   * Mostra una lista di oggetti del DOM.
   * (Alias per CSS opacity: 1)
   * @param  {...String} args Elementi da mostrare (css selectors)
   */
  show: function (...args) {
    args.forEach((arg) => {
      const elements = this.get(arg)
      elements.forEach((element) => (element.style.opacity = 1))
    })
  }
}

/**
 * ANIMAZIONE SCROLL
 */
export class MoveScroll {
  constructor () {
    this.start = 0
    this.distance = 0
    this.currentTime = 0
    this.increment = 5
    this.instance = 'null'
  }

  setAnimation (target, deltaStart) {
    if (!deltaStart) {
      deltaStart = 0
    }
    this.start = window.scrollY + this.increment + 1
    console.log('this.start ', this.start)
    this.distance = perc('timeline', 110)
    // console.log('event in scroll animation', this.event, this.first)
    this.instance = setInterval(() => {
      console.log('---------------ppp-------------', window.scrollY, this.prevScroll)
      if (window.scrollY >= this.distance) {
        clearInterval(this.instance)
        this.currentTime = 0
        this.prevScroll = 0
        console.log('---------------- stop intervall ----------------')
      } else {
        this.currentTime += this.increment
        const valTo = Math.easeInOutQuad(
          this.currentTime,
          this.start,
          this.distance,
          500
        )
        console.log('intervall', valTo)
        window.scrollTo(0, valTo)
      }
    }, 1000 / 60)
  }

  addListener () {
    let firstWheel = 0
    window.addEventListener('wheel', function (event) {
      console.log('firstWheel prima', firstWheel)

      if (firstWheel === 0) {
        console.log('firstWheel è 0')
        console.log('NELLO SCROLL')
        event.preventDefault()
        event.stopImmediatePropagation()
        event.stopPropagation()
        this.setAnimation('timeline')
      }

      firstWheel = 1
      console.log('firstWheel dopo', firstWheel)
    })
  }
}
/**
 * FUNZIONI FADE
 */
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) {
    return (c / 2) * t * t + b
  }
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

Math.easeInCubic = function (t, b, c, d) {
  var tc = (t /= d) * t * t
  return b + c * tc
}

Math.inOutQuintic = function (t, b, c, d) {
  var ts = (t /= d) * t
  var tc = ts * t
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc)
}
