import React from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@material-ui/core'

import apioLogo from 'assets/images/about-us/apio-logo.png'
import openTimeLogo from 'assets/images/about-us/open-timestamps-logo.png'
import ibmLogo from 'assets/images/about-us/ibm-logo.png'
import varGroupLogo from 'assets/images/about-us/var-group-logo.png'

const SectionTeam = () => {
  const { t } = useTranslation(['aboutUs'])
  return (
    <Grid container spacing={1} className='grid-team'>
      <Grid item xs={12} md={4}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <h4>{t('team.title1')}</h4>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className='logo-apio'>
              <a
                href='https://www.apio.cc/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={apioLogo} alt='' />
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <h4>Powered By</h4>
        <a
          href='https://opentimestamps.org/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img className='timestamp-img' src={openTimeLogo} alt='' />
        </a>
      </Grid>
      <Grid item xs={12} md={4} className='grid-logo-partner'>
        <Grid container>
          <Grid item xs={12} md={12}>
            <h4>{t('team.title2')}</h4>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              <a
                href='https://www.vargroup.it/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={varGroupLogo} alt='' />
              </a>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div>
              <a
                href='https://www.ibm.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={ibmLogo} alt='' />
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SectionTeam
