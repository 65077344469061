import React from 'react'
import PropTypes from 'prop-types'
// import { useTranslation } from 'react-i18next'
// import { makeStyles } from '@material-ui/core/styles'

// import InfoArea from 'components/InfoArea/InfoArea'

// import PinDrop from '@material-ui/icons/PinDrop'
// import Phone from '@material-ui/icons/Phone'
// import BusinessCenter from '@material-ui/icons/BusinessCenter'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import ModalSignupForm from './SignUp'

import { makeStyles } from '@material-ui/core/styles'
import style from 'assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js'

import apioLogo from 'assets/images/about-us/apio-logo.png'
import ibmLogo from 'assets/images/about-us/ibm-logo.png'
import varGroupLogo from 'assets/images/about-us/var-group-logo.png'
import { useTranslation } from 'react-i18next'
// import contactUsStyle from 'assets/jss/material-kit-pro-react/views/contactUsStyle.js'

// const useStyles = makeStyles(contactUsStyle)

const useStyles = makeStyles(style)

const FormScreen = ({ parentCallback }) => {
  // const { t } = useTranslation(['contactForm', 'contactModal'])
  // const classes = useStyles()
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (

    <>

      <ModalSignupForm parentCallback={parentCallback} />
      <div className='formMainContainer'>
        <h4 style={{ textAlign: 'center' }}>{t('common:account')}<a style={{ color: '#58c4ca' }} href='https://app.trusty.id/' target='_blank' rel='noopener noreferrer' >{t('common:click')}</a></h4>
      </div>
      <div className='logoMainContainer'>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} className={classes.mlAuto}>
            <GridContainer justify='center' alignItems='center' className='logoContainer'>
              <GridItem xs={4} sm={4} md={4}><a href='https://www.vargroup.it/' target='_blank' rel='noopener noreferrer'> <img className='logo' src={varGroupLogo} alt='' /></a></GridItem>
              <GridItem xs={4} sm={4} md={4}><a href='https://www.apio.cc/' target='_blank' rel='noopener noreferrer'><img className='logo' src={apioLogo} alt='' /></a></GridItem>
              <GridItem xs={4} sm={4} md={4}><a href='https://www.ibm.com/it-it/blockchain/solutions/food-trust' target='_blank' rel='noopener noreferrer'><img className='logo' src={ibmLogo} alt='' /></a></GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </>

  )
}

export default FormScreen

FormScreen.propTypes = {
  parentCallback: PropTypes.func
}
