import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import Button from 'components/Button'
import configuration from '../../configuration'

const SchedaHome = (props) => {
  const { t } = useTranslation(['home'])
  const { title, text, image, left, background, id } = props

  const IMAGE = (
    <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
      <div
        style={{
          width: '40vw',
          height: 'auto',
          margin: 'auto'
        }}
      >
        <img
          src={image}
          style={{
            width: '100%',
            height: 'auto'
          }}
          alt=''
        />
      </div>
    </Grid>
  )

  const TEXT = (
    <Grid item xs={12} sm={6} className='scheda-home-text'>
      <h1 className='freeDoka'>{title}</h1>
      <h2>{text}</h2>
      <Button
        className='btn-primary btn-intro'
        size='lg'
        type='button'
        href={configuration.pageLinks.trustyApp}
        target='_blank'
        id={'intro-c' + id}
      >
        {t('home:sectionCard:btnText')}
      </Button>
    </Grid>
  )

  return (
    <div className={'scheda-home ' + background} id={'scheda-' + id}>
      <Grid container alignItems='center' style={{ height: '100vh' }}>
        {left ? (
          <>
            {IMAGE} {TEXT}
          </>
        ) : (
          <>
            {TEXT} {IMAGE}
          </>
        )}
      </Grid>
    </div>
  )
}

export default SchedaHome

SchedaHome.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  image: PropTypes.string,
  left: PropTypes.bool,
  background: PropTypes.string,
  id: PropTypes.number
}
