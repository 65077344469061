import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import Button from 'components/Button'
// import configuration from '../../configuration'

import Modal from 'components/Modal'
import ModalSignUpForm from '../ModalSignUp'

const SchedaHomeMobile = (props) => {
  const { t } = useTranslation(['home, common'])
  const { title, text, image, background, id } = props
  const [modalVisible, setModalVisible] = useState(false)
  const handleClick = () => setModalVisible(true)

  const handleCloseSignUp = () => setModalVisible(false)

  const IMAGE = (
    <div className='mobile-section'>
      <div
        style={{
          width: '100vw',
          height: 'auto',
          margin: 'auto'
        }}
      >
        <div className='illustration'>
          <img
            src={image}
            className='mobile-img'
            style={{ marginTop: '-5vh' }}
            alt=''
          />
        </div>
      </div>
    </div>
  )

  const TEXT = (
    <div className='scheda-home-text'>
      <h1 className='freeDoka card-title'>{title}</h1>
      <div className='overlay'>
        <h2>
          {text}
          <Button
            className='btn-primary btn-intro-mobile'
            size='lg'
            type='button'
            // href={configuration.pageLinks.trustyApp}
            onClick={handleClick}
            target='_blank'
            id={'intro-mobile-c' + id}
          >
            {t('home:sectionCard:btnText')}
          </Button>
          <Modal
            className='mobileModal'
            visible={modalVisible}
            setVisible={setModalVisible}
            title={t('common:entra-gratis')}
            closable={true}
          >
            <div className='modal-app mobile' parentCallback={setModalVisible}>
              <ModalSignUpForm
                parentCallback={handleCloseSignUp}
              />
            </div>
          </Modal>
        </h2>
      </div>
    </div>
  )

  return (
    <div className = { 'scheda-home ' + background } id = { 'scheda-mobile' + id } >
      <Grid container alignItems='center'>
        <>
          {TEXT} {IMAGE}
        </>
      </Grid>
    </div >
  )
}

export default SchedaHomeMobile

SchedaHomeMobile.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  image: PropTypes.string,
  background: PropTypes.string,
  id: PropTypes.number
}
