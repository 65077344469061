import React from 'react'

import { Grid } from '@material-ui/core'

import logo1 from 'assets/images/about-us/banner/europe-flag.jpg'
import logo2 from 'assets/images/about-us/banner/BlockIS-logo.png'

const SectionEuBanner = () => {
  return (
    <Grid container id='eu-banner'>
      <Grid item xs={12} md={12} className='loghi'>
        <img src={logo1} alt='' />
        <img src={logo2} alt='' />
      </Grid>
      <Grid item xs={12} md={12}>
        <h4>
          This project has indirectly received funding from the European Union’s
          Horizon 2020 research and innovation programme under project Block.IS
          (grant agreement No 824509).
        </h4>
      </Grid>
    </Grid>
  )
}

export default SectionEuBanner
