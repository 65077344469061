import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import configuration from '../../configuration'
import { useTranslation } from 'react-i18next'
import { isMobile, isTablet } from 'Utils'

import logoFooter from 'assets/images/loghi/logo-footer-black.png'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import { Container } from '@material-ui/core'

import ModalSignUpForm from '../ModalSignUp'
import Modal from '../Modal'

// @material-ui/icons
import Close from '@material-ui/icons/Close'

import Button from 'components/Button'

// core components
import styles from 'assets/jss/material-kit-pro-react/components/headerStyle.js'

import LogoImg from 'assets/images/loghi/trusty-logo-black.svg'

const useStyles = makeStyles(styles)

export default function HeaderStructure (props) {
  const { t } = useTranslation(['signupModal, common'])
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const classes = useStyles()
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange)
    }
    return function cleanup () {
      if (props.changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange)
      }
    }
  })
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props

    const windowsScrollTop = window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[color])
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[changeColorOnScroll.color])
    } else {
      document.body
        .getElementsByTagName('header')[0]
        .classList.add(classes[color])
      document.body
        .getElementsByTagName('header')[0]
        .classList.remove(classes[changeColorOnScroll.color])
    }
  }
  const { color, links, fixed, absolute, rightHidden, setSignupVisible } = props
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  })

  const handleClickLogo = (e) => {
    if (isMobile() || isTablet()) {
      e.preventDefault()
      handleDrawerToggle()
    }
  }

  const [MobileModalVisible, setMobileModalVisible] = useState(false)
  const handleClickMobileModal = () => setMobileModalVisible(true)

  const handleCloseModal = () => setMobileModalVisible(false)

  return (
    <AppBar className={appBarClasses}>
      <Container id='header-container'>
        <Toolbar>
          <a
            className='navbar-brand'
            href={configuration.pageLinks.home}
            onClick={handleClickLogo}
          >
            <Hidden xsDown>
              {/* <Hidden smDown> */}
              <div className='logo-image' style={{ width: '100px' }}>
                <img src={logoFooter} alt='' width='100%' className='img-fluid' />
              </div>
            </Hidden>

            <Hidden smUp>
              {/* <Hidden mdUp> */}
              <div className='logo-image'>
                <img src={LogoImg} alt='' className='img-fluid' />
              </div>
            </Hidden>
          </a>

          {/* Bottone Provalo gratis tablet */}
          <Hidden mdUp>
            {/* {isTablet() ? ( */}
            <div
              style={{
                opacity: rightHidden ? '0' : '1'
              }}
              className='right'
            >
              <Button
                className={classes.navLink + ' btn-primary'}
                onClick={() => setSignupVisible(true)}
              >
                {t('signup')}
              </Button>

              <Button
                className={classes.navLink + ' btn-secondary'}
                href='https://app.trusty.id/'
                type='button'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('login')}
              </Button>
            </div>
            {/* ) : null} */}
          </Hidden>

          <Hidden smDown implementation='css' className={classes.hidden}>
            <div className={classes.collapse}>{links}</div>
          </Hidden>
          {/* Bottone provalo gratis mobile */}
          <Hidden smUp>
            <Button
              onClick={handleClickMobileModal}
              className='btn-primary enterMobile'
              type='button'
              style={{
                right: '0',
                position: 'absolute',
                fontSize: '0.6rem',
                padding: '7px'
              }}
            >
              {t('common:signup')}

            </Button>
            <Modal
              className='mobileModal'
              visible={MobileModalVisible}
              setVisible={setMobileModalVisible}
              title={t('common:entra-gratis')}
              closable={true}
            >
              <div className='modal-app mobile'>
                <ModalSignUpForm
                  parentCallback={handleCloseModal}
                />
              </div>
            </Modal>
          </Hidden>
        </Toolbar>
      </Container>
      <Drawer
        variant='temporary'
        anchor={'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
          className={classes.closeButtonDrawer}
        >
          <Close />
        </IconButton>
        <div className={classes.appResponsive}>{links}</div>
      </Drawer>
    </AppBar>
  )
}

HeaderStructure.defaultProp = {
  color: 'white'
}

HeaderStructure.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
    'sky'
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  rightHidden: PropTypes.bool,
  setSignupVisible: PropTypes.func,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'transparent',
      'white',
      'rose',
      'dark'
    ]).isRequired
  })
}
