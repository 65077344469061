import React from 'react'
import { useTranslation } from 'react-i18next'
import configuration from '../../configuration'

import FooterStructure from './FooterStructure'

import { makeStyles } from '@material-ui/core/styles'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Iubenda from 'react-iubenda-policy'
import styles from 'assets/jss/material-kit-pro-react/views/ecommerceStyle.js'

// import PrivacyModal from '../Legal/PrivacyModal'
// import CookieModal from '../Legal/CookieModal'
import logoFooter from 'assets/images/loghi/logo-footer-black.png'

const useStyles = makeStyles(styles)

const Footer = () => {
  const { t } = useTranslation(['footer'])
  const classes = useStyles()
  const privacyPolicy = 74688903

  return (
    <FooterStructure
      theme='white'
      content={
        <div className='bottom-content'>
          <div className='left-content'>
            <List>
              <ListItem>
                {/* <PrivacyModal/> */}
                <Iubenda id={privacyPolicy} type='privacy' styling='white'>
                  Privacy Policy
                </Iubenda>
              </ListItem>
              <ListItem>
                {/* <CookieModal /> */}
                <Iubenda id={privacyPolicy} type='cookie' styling='white'>
                  Cookie Policy
                </Iubenda>
              </ListItem>
            </List>
          </div>
          <div className='right-content'>
            Copyright &copy; 2020{' '}
            <a
              href='https://www.apio.cc/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Apio s.r.l.
            </a>
          </div>
        </div>
      }
    >
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <h5>
            <a href={configuration.pageLinks.home}>
              <img
                src={logoFooter}
                style={{ height: '35px', width: 'auto' }}
                alt=''
              />
            </a>
          </h5>
          <p>{t('text')}</p>
        </GridItem>
        <GridItem id='footer-links' xs={12} sm={2} md={2}>
          <div className='title'>Pages</div>
          <List>
            <ListItem>
              <a href={configuration.pageLinks.home}>Home</a>
            </ListItem>
            <ListItem>
              <a href={configuration.pageLinks.press}>Press</a>
            </ListItem>
            <ListItem>
              <a href={configuration.pageLinks.aboutUs}>About Us</a>
            </ListItem>
          </List>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} className='socialSection'>
          <div className='title'>Social Feed</div>
          <div className={classes.socialFeed}>
            <div>
              <a href='https://www.linkedin.com/company/trusty-a-true-story' target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-linkedin' />
                <p>{t('social.linkedin')}</p>
              </a>
              <a href='https://www.facebook.com/trustyItaly' target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-facebook' />
                <p>{t('social.facebook')}</p>
              </a>
              <a href='https://www.instagram.com/trustyofficial_/' target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-instagram' />
                <p>{t('social.instagram')}</p>
              </a>

              <a href='https://www.youtube.com/channel/UCC-Yqqtk-OlA_6oTsVCEX0A' target='_blank' rel='noopener noreferrer'>
                <i className='fab fa-youtube' />
                <p>{t('social.youtube')}</p>
              </a>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </FooterStructure>
  )
}

export default Footer
