import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'Utils'

// Components
import Header from 'components/Header'

// Sections
import DesktopVersion from './DesktopVersion'
import MobileVersion from './MobileVersion'

// Animations
import Animations from './ScrollAnimation/Animations'
import { get as getDesktop } from './ScrollAnimation/scenes/desktop'
import { get as getMobile } from './ScrollAnimation/scenes/mobile'

let first = 0

const Home = ({ setSignupVisible }) => {
  const [rightHidden, setRightHidden] = useState(true)
  const vh = window.innerHeight
  useEffect(() => {
    window.location.href = 'https://www.trusty.id/'
  }, [])
  console.log('SONO HOME!!!!!!!!!!!!!!')

  const loadAnimation = () => {
    if (isMobile() && first === 0) {
      console.log('sono mobile')
      document.documentElement.style.setProperty('--vh', `${vh * 1.2}`)
      Animations.load(getMobile())
    } else if (!isMobile() && first === 0) {
      console.log('sono desktop')
      document.documentElement.style.setProperty('--vh', `${vh * 1.5}`)
      Animations.load(
        getDesktop({
          setRightHidden: setRightHidden
        })
      )
    }
    first = 1
  }

  window.addEventListener('load', () => {
    setTimeout(() => {
      loadAnimation()
    }, 1000)
  })

  return (
    <>
      <div
        id='header-bg-sky'
        style={{
          position: 'fixed',
          width: '100vw',
          height: 0,
          top: 0,
          left: 0,
          backgroundColor: '#C8E0E8',
          zIndex: 1000
        }}
      ></div>
      <div
        id='header-bg-orange'
        style={{
          position: 'fixed',
          width: '100vw',
          height: '99px',
          top: 0,
          left: 0,
          backgroundColor: '#F7B6B6',
          opacity: 0,
          zIndex: 1000
        }}
      ></div>
      <Header
        scroll={false}
        rightHidden={rightHidden}
        setSignupVisible={setSignupVisible}
      />
      {isMobile() ? (<MobileVersion />) : !isMobile() ? (<DesktopVersion setSignupVisible={setSignupVisible} />) : null}
    </>
  )
}

export default Home

Home.propTypes = {
  setSignupVisible: PropTypes.func
}
