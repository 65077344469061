import React from 'react'
import { Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// import qrImg from 'assets/images/home/home/qr-code.svg'

const SectionQrCode = () => {
  const { t } = useTranslation(['home'])
  return (
    <div id='home-mobile-qr' className='bg-white mainContainer mobileSection'>
      <div className='home-mobile-qr-content'>
        <Container maxWidth='lg'>
          <h1>
            {t('home:sectionQrMobile:title1')} <br />  {t('home:sectionQrMobile:title2')}
          </h1>
        </Container>
      </div>
    </div>
  )
}

export default SectionQrCode
