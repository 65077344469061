import React from 'react'

import SchedaHomeMobile from 'components/SchedaHomeMobile'
import GetDataCards from 'components/SchedaHome/dataCards'

const SectionCardsMobile = () => {
  const dataCards = GetDataCards()

  return (
    <div id='home-schede-mobile' className='scheda-mobile mobileSection'>
      {dataCards.map((item, i) => (
        <SchedaHomeMobile
          key={item.id}
          id={item.id}
          title={item.title}
          image={item.image}
          text={item.text}
          background={item.background}
        />
      ))}
    </div>
  )
}

export default SectionCardsMobile
