import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { Container, Dialog, DialogContent, Button, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Header from 'components/Header'
import Footer from 'components/Footer'
import MetaTags from 'react-meta-tags'

import Parallax from 'components/Parallax'
import { useTranslation } from 'react-i18next'
import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js'

const useStyles = makeStyles(aboutUsStyle)

const PrivacyPolicy = ({ setSignupVisible }) => {
  const { t } = useTranslation(['privacy-policy , cookie-policy'])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  })

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const classes = useStyles()
  return (
    <>
      <MetaTags>
        <title>{t('privacy-policy:metaTitle')}</title>
        <meta name="description" content={t('privacy-policy:metaDescription')} />
        <meta property="og:title" content={t('privacy-policy:metaTitle')} />
        <meta name="robots" content="noindex" />
      </MetaTags>
      <Header page={3} setSignupVisible={setSignupVisible} />
      <div id='privacy-policy' >
        <Parallax small className='top' >
          <div className='title-page' >
            <h1 > Privacy Policy </h1>
          </div>

        </Parallax>
        <div className={classes.main} style={{ paddingTop: '1px' }} >
          <Container >
            <section >
              <h2 className='freeDoka'> {t('privacy-policy:titolo')} </h2>
              <h4 >{t('privacy-policy:trattamento-dati:titolo')}</h4>
              <p>{t('privacy-policy:trattamento-dati:testo')}</p>
              <h4>{t('privacy-policy:tipologia-dati:titolo')}</h4>
              <p>{t('privacy-policy:tipologia-dati:testo')}</p>
              <h4>{t('privacy-policy:conservazione-dati:titolo')}</h4>
              <p>{t('privacy-policy:conservazione-dati:testo1')} <span style={{ color: '#3C4858', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClickOpen}>
                link
              </span> {t('privacy-policy:conservazione-dati:testo2')}</p>
              <p>{t('privacy-policy:diffusione-dati:testo')}</p>
              <ul>
                <li>{t('privacy-policy:diffusione-dati:li1')}</li>
                <li>{t('privacy-policy:diffusione-dati:li2')}</li>
              </ul>
              <p>{t('privacy-policy:diffusione-dati:pie-di-pagina')}</p>
              <h4>{t('privacy-policy:diritti-dati:titolo')}</h4>
              <p>{t('privacy-policy:diritti-dati:testo')}</p>
              <ul>
                <li>{t('privacy-policy:diritti-dati:li1')}</li>
                <li>{t('privacy-policy:diritti-dati:li2')}</li>
                <li>{t('privacy-policy:diritti-dati:li3')}</li>
                <li>{t('privacy-policy:diritti-dati:li4')}</li>
                <li>{t('privacy-policy:diritti-dati:li5')}</li>
                <li>{t('privacy-policy:diritti-dati:li6')}</li>
                <li>{t('privacy-policy:diritti-dati:li7')}</li>
              </ul>
              <p>{t('privacy-policy:diritti-dati:testo2')}</p>
              <h4>{t('privacy-policy:identita-dati:titolo')}</h4>
              <p>{t('privacy-policy:identita-dati:testo')}</p>
            </section>
            <section>
              <h2 className='freeDoka'> {t('cookie-policy:titolo')}</h2>

              <h3>{t('cookie-policy:uso-cookie:titolo')}</h3>
              <p>{t('cookie-policy:uso-cookie:testo')}</p>
              <ul>
                <li>{t('cookie-policy:uso-cookie:li1')}</li>
                <li>{t('cookie-policy:uso-cookie:li2')}</li>
                <li>{t('cookie-policy:uso-cookie:li3')}</li>
              </ul>
              <h2>{t('cookie-policy:tipologia-cookie:titolo')}</h2>
              <h3>{t('cookie-policy:tipologia-cookie:titolo-tecnici')}</h3>
              <p>{t('cookie-policy:tipologia-cookie:testo-tecnici')}</p>
              <ul>
                <li>{t('cookie-policy:tipologia-cookie:li1-tecnici')}</li>
                <li>{t('cookie-policy:tipologia-cookie:li2-tecnici')}</li>
              </ul>
              <h3>{t('cookie-policy:tipologia-cookie:titolo-analitici')}</h3>
              <p>{t('cookie-policy:tipologia-cookie:testo-analitici')}</p>
              <h3>{t('cookie-policy:tipologia-cookie:titolo-terzi')}</h3>
              <p>{t('cookie-policy:tipologia-cookie:testo-terzi')}</p>
              <h3>{t('cookie-policy:tipologia-cookie:titolo-integrazione')}</h3>
              <p>{t('cookie-policy:tipologia-cookie:testo-integrazione')}</p>
              <h3>{t('cookie-policy:tipologia-cookie:titolo-profilazione')}</h3>
              <p>{t('cookie-policy:tipologia-cookie:testo-profilazione')}</p>
              <ul>
                <li>{t('cookie-policy:tipologia-cookie:li1-profilazione')}</li>
                <li>{t('cookie-policy:tipologia-cookie:li2-profilazione')}</li>
              </ul>
              <p><strong>{t('cookie-policy:tipologia-cookie:pie-di-pagina-profilazione')}</strong></p>
              <h3>{t('cookie-policy:servizi-terzi:titolo')}</h3>
              <p>{t('cookie-policy:servizi-terzi:testo')}</p>

              <p>{t('cookie-policy:servizi-terzi:aggiornamento')}</p>

              <h2>{t('cookie-policy:disabilitare:titolo')}</h2>
              <p>{t('cookie-policy:disabilitare:titolo')}</p>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></td><td>https://support.mozilla.org/it/kb/Gestione%20dei%20cookie
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">Google Chrome</a>
                    </td>
                    <td>https://support.google.com/chrome/answer/95647?hl=it
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies" target="_blank" rel="noopener noreferrer">Internet Explorer</a>
                    </td>
                    <td>http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://support.apple.com/kb/PH17191?viewlocale=it_IT&amp;locale=en_US" target="_blank" rel="noopener noreferrer">Safari 6/7 Mavericks</a>
                    </td>
                    <td>https://support.apple.com/kb/PH17191?viewlocale=it_IT&amp;locale=it_IT
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://support.apple.com/kb/PH19214?viewlocale=it_IT&amp;locale=en_US" target="_blank" rel="noopener noreferrer">Safari 8 Yosemite</a>
                    </td>
                    <td>https://support.apple.com/kb/PH19214?viewlocale=it_IT&amp;locale=it_IT</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://support.apple.com/it-it/HT201265" target="_blank" rel="noopener noreferrer">Safari su iPhone, iPad, o iPod touch</a>
                    </td>
                    <td>https://support.apple.com/it-it/HT201265
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>{t('cookie-policy:tipologie-cookie:disabilitare:pie-di-pagina')} </p>

            </section>

            <Dialog
              open={open}
              maxWidth= "lg"
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              style={{ margin: 0 }}
              className="cookieModal"
              scroll="paper"
            >
              <DialogTitle>
                <Button style={{ float: 'right' }}onClick={handleClose} color="primary">
                  <CloseIcon style={{ fontSize: '2em', color: 'black' }}/>
                </Button>
              </DialogTitle>
              <DialogContent>
                <iframe title="privacyIframe" loading="lazy" style={{ width: '65vw', height: '80vh', border: 0 }} src="https://www.iubenda.com/privacy-policy/74688903"></iframe>
              </DialogContent>

            </Dialog>

          </Container>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy

PrivacyPolicy.propTypes = {
  setSignupVisible: PropTypes.func
}
