import { TweenMax, Linear } from 'gsap'
import { perc } from 'Utils'

export const get = () => {
  const vh = window.innerHeight * 1.1

  return [
    {
      scene: {
        triggerElement: '#intro-mobile',
        offset: 0,
        duration: (vh * 0.30) + 'px'
      },
      debug: { name: 'pin', indent: -60 },
      sceneMethods: {
        setPin: {
          element: '#intro-mobile',
          settings: { pushFollowers: true }
        }
      }
    },
    {
      scene: {
        triggerElement: '#timeline',
        offset: 0,
        duration: (vh * 0.2) + 'px'
      },
      debug: { name: 'opacity' },
      tween: TweenMax.to('#intro-mobile-text', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // tiro su la bottiglia
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 0),
        duration: (vh * 0.3) + 'px'
      },
      debug: { name: 'translate bottle' },
      tween: TweenMax.to('#intro-mobile-bottiglia', 1, {
        y: '-40%',
        ease: Linear.easeNone
      })
    },
    // tiro su l'immagine che rappresenta l'inquadratura della fotocamera
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 0),
        duration: (vh * 0.3) + 'px'
      },
      debug: { name: 'translate qr camera' },
      tween: TweenMax.to('.intro-mobile-qr-camera', 1, {
        y: '-67%',
        ease: Linear.easeNone
      })
    },
    // zoom QR
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 25),
        duration: '3px'
      },
      debug: { name: 'zoom' },
      tween: TweenMax.to('.intro-mobile-qr', 1, {
        scale: 2.8,
        ease: Linear.easeNone
      })
    },
    // zoom QR Camera
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 25),
        duration: (vh * 1) + 'px'
      },
      debug: { name: 'zoom' },
      tween: TweenMax.to('#intro-mobile-total-qr', 1, {
        scale: 15,
        y: '780%',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 37),
        duration: '1.3%'
      },
      debug: { name: 'fade OUT', indent: 110 },
      tween: TweenMax.to('#intro-mobile-total-qr', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 30),
        duration: '7%'
      },
      debug: { name: 'Fade in top mobile' },
      tween: TweenMax.to('#bottigliaOlio', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // ------------- TOP MOBILE HOME----------
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 0),
        duration: (vh * 0.45) + 'px'
      },
      debug: { name: 'Pin Top mobile' },
      sceneMethods: {
        setPin: {
          element: '#top-home-mobile',
          settings: { pushFollowers: true }
        }
      }
    },
    {
      scene: {
        triggerElement: '#timeline',
        offset: perc('timeline', 38),
        duration: '1.8%'
      },
      debug: { name: 'Fade in top mobile' },
      tween: TweenMax.to('#top-home-mobile', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#top-home-mobile',
        offset: perc('#top-home-mobile', 99),
        duration: '3%'
      },
      debug: { name: 'Fade in top mobile' },
      tween: TweenMax.to('#top-home-mobile', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // ---------------- Text Come funziona ----------------
    {
      scene: {
        triggerElement: '#card-how-mobile',
        offset: 0,
        duration: (vh * 3) + 'px'
      },
      debug: { name: 'pin & scale text', indent: 130 },
      tween: TweenMax.to('#card-how-mobile div', 1, {
        scale: 50,
        ease: Linear.easeNone
      }),
      sceneMethods: {
        setPin: {
          element: '#card-how-mobile',
          settings: { pushFollowers: false }
        }
      }
    },
    {
      scene: {
        triggerElement: '#card-how-mobile',
        offset: perc('#card-how-mobile', 10),
        duration: '20%'
      },
      debug: { name: 'opacity text' },
      tween: TweenMax.to('#card-how-mobile div', 1, {
        opacity: 0,
        ease: Linear.easeNone
      })
    },
    // ------------  CARDS -----------------------
    // {
    //   scene: {
    //     triggerElement: '#card-how-mobile',
    //     offset: perc('#card-how-mobile', 0),
    //     duration: '6%'
    //   },
    //   debug: { name: 'Pin schede home', indent: 0 },
    //   sceneMethods: {
    //     setPin: {
    //       element: '#home-schede-mobile',
    //       settings: { pushFollowers: true }
    //     }
    //   }
    // },
    {
      scene: {
        triggerElement: '#card-how-mobile',
        offset: perc('#card-how-mobile', 20),
        duration: '5%'
      },
      debug: { name: 'Fade in schede home', indent: 0 },
      tween: TweenMax.to('#home-schede-mobile', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },

    // ---------------- CARD 1-----------------
    {
      scene: {
        triggerElement: '#card-how-mobile',
        offset: perc('#card-how-mobile'),
        duration: (vh * 2.05) + 'px'
      },
      debug: { name: 'card 1', indent: 0 },
      sceneMethods: {
        setPin: {
          element: '#scheda-mobile1',
          settings: { pushFollowers: false }
        }
      }
    },

    // ---------------- ANIMAZIONI INTERNO SCHEDE-----------------
    {
      scene: {
        triggerElement: '#scheda-mobile1',
        offset: perc('#scheda-mobile1', 20),
        duration: '30%'
      },
      debug: { name: 'Start Overlay', indent: 120 },
      tween: TweenMax.to('#scheda-mobile1 .overlay h2', 1, {
        height: (vh * 0.36) + 'px',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-mobile1',
        offset: perc('#scheda-mobile1', 20),
        duration: '30%'
      },
      debug: { name: 'Img mobile', indent: 120 },
      tween: TweenMax.to('#scheda-mobile1 .mobile-img', 1, {
        scale: 0.9,
        ease: Linear.easeNone
      })
    },

    // ---------------- CARD 2-----------------

    {
      scene: {
        triggerElement: '#scheda-mobile2',
        offset: 0,
        duration: (vh * 1.3) + 'px'
      },
      debug: { name: 'card 2' },
      sceneMethods: {
        setPin: {
          element: '#scheda-mobile2',
          settings: { pushFollowers: false }
        }
      }
    },

    // -------------- ANIMAZIONE INTERNA -------------------------
    {
      scene: {
        triggerElement: '#scheda-mobile2',
        offset: 0,
        duration: '30%'
      },
      debug: { name: 'Start Overlay', indent: 120 },
      tween: TweenMax.to('#scheda-mobile2 .overlay h2', 1, {
        height: (vh * 0.32) + 'px',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-mobile2',
        offset: 0,
        duration: '30%'
      },
      debug: { name: 'Img mobile', indent: 120 },
      tween: TweenMax.to('#scheda-mobile2 .mobile-img', 1, {
        scale: 0.8,
        ease: Linear.easeNone
      })
    },

    // ---------------- CARD 3-----------------
    {
      scene: {
        triggerElement: '#scheda-mobile3',
        offset: 0,
        duration: (vh * 1.3) + 'px'
      },
      debug: { name: 'card 3', indent: -80 },
      sceneMethods: {
        setPin: {
          element: '#scheda-mobile3',
          settings: { pushFollowers: false }
        }
      }
    },

    // -------------- ANIMAZIONE INTERNA -------------------------
    {
      scene: {
        triggerElement: '#scheda-mobile3',
        offset: 0,
        duration: '30%'
      },
      debug: { name: 'Start Overlay', indent: 120 },
      tween: TweenMax.to('#scheda-mobile3 .overlay h2', 1, {
        height: (vh * 0.28) + 'px',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-mobile3',
        offset: 0,
        duration: '30%'
      },
      debug: { name: 'Img mobile', indent: 120 },
      tween: TweenMax.to('#scheda-mobile3 .mobile-img', 1, {
        scale: 0.8,
        ease: Linear.easeNone
      })
    },

    // ---------------- CARD 4-----------------
    {
      scene: {
        triggerElement: '#scheda-mobile4',
        offset: 0,
        duration: (vh * 1.3) + 'px'
      },
      debug: { name: 'card 4' },
      sceneMethods: {
        setPin: {
          element: '#scheda-mobile4',
          settings: { pushFollowers: false }
        }
      }
    },

    // -------------- ANIMAZIONE INTERNA -------------------------
    {
      scene: {
        triggerElement: '#scheda-mobile4',
        offset: 0,
        duration: '30%'
      },
      debug: { name: 'Start Overlay', indent: 120 },
      tween: TweenMax.to('#scheda-mobile4 .overlay h2', 1, {
        height: (vh * 0.31) + 'px',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-mobile4',
        offset: 0,
        duration: '30%'
      },
      debug: { name: 'Img mobile', indent: 120 },
      tween: TweenMax.to('#scheda-mobile4 .mobile-img', 1, {
        scale: 0.8,
        ease: Linear.easeNone
      })
    },

    // ---------------- CARD 5-----------------
    {
      scene: {
        triggerElement: '#scheda-mobile5',
        offset: 0,
        duration: (vh * 0.22) + 'px'
      },
      debug: { name: 'card 5' },
      sceneMethods: {
        setPin: {
          element: '#scheda-mobile5',
          settings: { pushFollowers: false }
        }
      }
    },

    // -------------- ANIMAZIONE INTERNA -------------------------
    {
      scene: {
        triggerElement: '#scheda-mobile5',
        offset: 0,
        duration: '20%'
      },
      debug: { name: 'Start Overlay', indent: 120 },
      tween: TweenMax.to('#scheda-mobile5 .overlay h2', 1, {
        height: (vh * 0.29) + 'px',
        ease: Linear.easeNone
      })
    },
    {
      scene: {
        triggerElement: '#scheda-mobile5',
        offset: 0,
        duration: '20%'
      },
      debug: { name: 'Img mobile', indent: 120 },
      tween: TweenMax.to('#scheda-mobile5 .mobile-img', 1, {
        scale: 0.8,
        ease: Linear.easeNone
      })
    },

    // ------------  SCHEDA QR -----------------------
    {
      scene: {
        triggerElement: '#home-mobile-qr',
        offset: 0,
        duration: (vh * 0.5) + 'px'
      },
      debug: { name: 'Start cosa aspetti' },
      sceneMethods: {
        setPin: {
          element: '#home-mobile-qr',
          // mettere a true per vecchia animazione
          settings: { pushFollowers: true }
        }
      }
    },
    // {
    //   scene: {
    //     triggerElement: '#home-mobile-qr',
    //     offset: (vh * 0.4) + 'px',
    //     duration: (vh * 1) + 'px'
    //   },
    //   debug: { name: 'move top sQR CODE', indent: 120 },
    //   tween: TweenMax.to('.home-mobile-qr-content', 1, {
    //     bottom: '100vh',
    //     ease: Linear.easeNone
    //   })
    // },
    // {
    //   scene: {
    //     triggerElement: '#home-mobile-qr',
    //     offset: perc('#home-mobile-qr', 200),
    //     duration: '10%'
    //   },
    //   debug: { name: 'QR CODE opacity', indent: 130 },
    //   tween: TweenMax.to('#home-mobile-qr', 1, {
    //     opacity: 0,
    //     ease: Linear.easeNone
    //   })
    // },
    // --------------- PRICING --------------
    // {
    //   scene: {
    //     triggerElement: '#scheda-pricing-mobile',
    //     offset: '-60vh',
    //     duration: (vh * 0.2) + 'px'
    //   },
    //   debug: { name: 'Pin prezzo', indent: 120 },
    //   sceneMethods: {
    //     setPin: {
    //       element: '#scheda-pricing-mobile',
    //       settings: { pushFollowers: false }
    //     }
    //   }
    // },
    // {
    //   scene: {
    //     triggerElement: '#home-mobile-qr',
    //     offset: perc('#home-mobile-qr', 190),
    //     duration: '5%'
    //   },
    //   debug: { name: 'scheda mobile container', indent: 120 },
    //   tween: TweenMax.to('#scheda-pricing-mobile', 1, {
    //     opacity: 1,
    //     ease: Linear.easeNone
    //   })
    // },
    // // --------------- PRICING 1 --------------
    // {
    //   scene: {
    //     triggerElement: '#scheda-pricing-mobile',
    //     offset: perc('#pricing-mobile1', -40),
    //     duration: '15%'
    //   },
    //   debug: { name: 'scheda prezzo 1' },
    //   tween: TweenMax.to('#pricing-mobile1', 1, {
    //     opacity: 1,
    //     ease: Linear.easeNone
    //   })
    // },
    // // // --------------- PRICING 2 --------------
    // {
    //   scene: {
    //     triggerElement: '#pricing-mobile2',
    //     offset: perc('#pricing-mobile2', -40),
    //     duration: '10%'
    //   },
    //   debug: { name: 'scheda prezzo 2' },
    //   tween: TweenMax.to('#pricing-mobile2', 1, {
    //     opacity: 1,
    //     ease: Linear.easeNone
    //   })
    // },
    // // // --------------- PRICING 3 --------------
    // {
    //   scene: {
    //     triggerElement: '#pricing-mobile3',
    //     offset: perc('#pricing-mobile3', -40),
    //     duration: '10%'
    //   },
    //   debug: { name: 'scheda prezzo 3' },
    //   tween: TweenMax.to('#pricing-mobile3', 1, {
    //     opacity: 1,
    //     ease: Linear.easeNone
    //   })
    // },
    // ------------  FOOTER -----------------------
    {
      scene: {
        triggerElement: '#mobile-empty-space',
        offset: perc('#mobile-empty-space', 0),
        duration: '2%'
      },
      debug: { name: 'opacity footer', indent: 120 },
      tween: TweenMax.to('#scheda-footer-mobile', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 1 --------------
    {
      scene: {
        triggerElement: '#mobile-empty-space',
        offset: perc('#mobile-empty-space', -5),
        duration: '10%'
      },
      debug: { name: 'titolo1' },
      tween: TweenMax.to('#titolo-mobile1', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 2 --------------
    {
      scene: {
        triggerElement: '#mobile-empty-space',
        offset: perc('#mobile-empty-space', -10),
        duration: '10%'
      },
      debug: { name: 'titolo2' },
      tween: TweenMax.to('#titolo-mobile2', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 3 --------------
    {
      scene: {
        triggerElement: '#mobile-empty-space',
        offset: perc('#mobile-empty-space', -15),
        duration: '10%'
      },
      debug: { name: 'titolo3' },
      tween: TweenMax.to('#titolo-mobile3', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    },
    // --------------- TITOLO 4 --------------
    {
      scene: {
        triggerElement: '#mobile-empty-space',
        offset: perc('#mobile-empty-space', -20),
        duration: '10%'
      },
      debug: { name: 'titolo4' },
      tween: TweenMax.to('#titolo-mobile4', 1, {
        opacity: 1,
        ease: Linear.easeNone
      })
    }
  ]
}
