import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import qrImage from 'assets/images/home/intro/qr-code.svg'
import productsImg from 'assets/images/home/intro/products.png'
import phoneImg from 'assets/images/home/intro/phone.png'
import scaffaleImg from 'assets/images/home/intro/scaffale.png'
import backMaskImg from 'assets/images/home/intro/back-color.png'

import Button from 'components/Button'

const SectionIntro = ({ setSignupVisible }) => {
  const { t } = useTranslation(['common', 'home'])
  const handleClick = () => setSignupVisible(true)

  const fixSideWidth = (e) => {
    const IMG_SIZE = { w: 1920, h: 1080 }
    const viewSize = { w: window.innerWidth, h: window.innerHeight }

    const imgWidth = (viewSize.h * IMG_SIZE.w) / IMG_SIZE.h

    const sides = document.getElementsByClassName('side-back-color')

    if (viewSize.w > imgWidth) {
      const sideSize = (viewSize.w - imgWidth) / 2 + 5

      sides.item(0).style.width = sideSize + 'px'
      sides.item(1).style.width = sideSize + 'px'
    }
  }

  useEffect(() => {
    // Fix side width
    window.addEventListener('load', fixSideWidth)
    window.addEventListener('resize', fixSideWidth)
  }, [])

  return (
    <>
      <div
        id='timeline'
        style={{ position: 'absolute', height: '100vh' }}
      ></div>
      <div id='intro'>
        <div
          className='side-back-color'
          style={{
            left: '0'
          }}
        ></div>
        <div
          className='side-back-color'
          style={{
            right: '0'
          }}
        ></div>

        <img id='intro-back-mask' src={backMaskImg} alt='' />
        <img id='intro-scaffali' src={scaffaleImg} alt='' />

        <img id='intro-phone' src={phoneImg} alt='' />

        <div id='intro-products'>
          <img
            src={productsImg}
            style={{ height: '100%', willChange: 'opacity' }}
            alt=''
          />
          <div className='qr-code'>
            <img src={qrImage} alt='' />
          </div>
        </div>

        <div id='intro-text'>
          <h1 style={{ marginBottom: '40px' }} className='freeDoka'>
            {t('home:sectionIntro:title')}
          </h1>
          <Button
            id='provalo-intro'
            className='btn-primary'
            size='lg'
            type='button'
            onClick={handleClick}
          >
            {t('common:signup')}
          </Button>
          <Button
            id='login-intro'
            href='https://app.trusty.id/'
            className='btn-secondary'
            size='lg'
            type='button'
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('common:login')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default SectionIntro

SectionIntro.propTypes = {
  setSignupVisible: PropTypes.func
}
