import React from 'react'
import ReactDOM from 'react-dom'

import './i18n'

// Theme style
import 'assets/scss/material-kit-pro-react.scss?v=1.9.0'

// Animate
import 'animate.css/animate.css'

// Custom style
import 'assets/css/custom/style.css'
import 'assets/css/custom/MediaQuery.css'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
