import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from '@material-ui/core'
import { isMobile } from 'Utils'
import ModalContactForm from '../../../components/ModalContact'
import Modal from 'components/Modal'
import Button from 'components/Button'

const SectionContact = () => {
  const { t } = useTranslation(['contactBanner', 'contactForm'])

  const [modalVisible, setModalVisible] = useState(false)
  const handleClick = () => setModalVisible(true)

  const handleCloseContact = () => setModalVisible(false)

  return (
    <section className='large contact-section bg-green'>
      <Container>
        <h1>{t('contactBanner:title')}</h1>
        <h3>{t('contactBanner:description')}</h3>
        <Button color='primary' onClick={handleClick}>
          {t('contactBanner:button-text')}
        </Button>
        {isMobile() ? (
          <Modal
            className='mobileModal'
            visible={modalVisible}
            setVisible={setModalVisible}
            title={t('contactForm:title')}
          >
            <div className='modal-app mobile scrollY'>
              <ModalContactForm
                parentCallback={handleCloseContact}
              />
            </div>
          </Modal>
        )
          : <Modal
            className='desktopModal contacts'
            visible={modalVisible}
            setVisible={setModalVisible}
            title={t('contactForm:title')}
          >
            <div className='modal-app desktop'>
              <ModalContactForm
                parentCallback={handleCloseContact}
              />
            </div>
          </Modal>
        }
      </Container>
    </section>
  )
}

export default SectionContact
