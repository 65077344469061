import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Container } from '@material-ui/core'

import ModalSignUpForm from 'components/ModalSignUp'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import './style.css'
import MetaTags from 'react-meta-tags'

const TrustyApp = () => {
  const [signupVisible, setSignupVisible] = useState(false)
  const { t } = useTranslation(['signupModal'])
  return (
    <>
      <MetaTags>
        <title>Trusty App</title>
        <meta name="description" content="" />
        <meta name="robots" content="noindex" />
      </MetaTags>
      <Modal
        className='desktopModal'
        visible={true}
        title={t('title')}
        closable={false}
      >
        <div className='modal-app desktop'>
          <ModalSignUpForm
            visible={setSignupVisible}
            parentCallback={signupVisible}
            closable={true}
          />
        </div>
      </Modal>
      <div id='trusty-app'>
        <Container className='onBoarding'>
          <div className='flexContainer'>
            <div className='sidebar'>
            </div>
            <div className='contentFlex'>
              <div className='headerOnBoarding'>
              </div>
              <div className='contentOnBoarding'>
              </div>
            </div>
          </div>
          <div className='menuIcon'>
          </div>
        </Container>
      </div>
    </>
  )
}

export default TrustyApp

TrustyApp.propTypes = {
  setSignupVisible: PropTypes.func
}
