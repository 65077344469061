import React from 'react'
import { useTranslation } from 'react-i18next'

import errorImg from 'assets/images/contact-modal/error.svg'

const ErrorScreen = () => {
  const { t } = useTranslation('contactModal')
  return (
    <div style={{ textAlign: 'center' }}>
      <h3>{t('feedback-error-text')}</h3>
      <br />
      <img style={{ width: '50%' }} src={errorImg} alt='' />
    </div>
  )
}

export default ErrorScreen
