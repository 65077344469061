import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import { Container } from '@material-ui/core'

import Header from 'components/Header'
import Footer from 'components/Footer'

import Parallax from 'components/Parallax'
import MetaTags from 'react-meta-tags'

import SectionTeam from './Sections/SectionTeam'
import SectionEuBanner from './Sections/SectionEuBanner'

import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js'

import ominoImg from 'assets/images/about-us/back.png'

const useStyles = makeStyles(aboutUsStyle)

const AboutUs = ({ setSignupVisible }) => {
  const { t } = useTranslation(['aboutUs'])
  React.useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    window.location.href = 'https://www.trusty.id/'
  }, [])
  const classes = useStyles()
  return (<>
    <MetaTags>
      <title>{t('metaTitle')}</title>
      <meta name="description" content={t('metaDescription')} />
      <meta property="og:title" content={t('metaTitle')} />
      {/* <meta property="og:image" content="path/to/image.jpg" /> */}
    </MetaTags>
    <Header page={3} setSignupVisible={setSignupVisible} />
    <div id='about-us' >
      <Parallax small className='top' >

        <div className='aboutFlex'>
          <div className='title-page' >
            <h1 > {t('top.title')} </h1>
            <h3 > {t('top.description')} </h3>
          </div>
          <div className='back-image-bg' >
            <img src={ominoImg} alt='' className='ominoImg' />
          </div>
        </div>

      </Parallax>
      <div className={classes.main} style={{ paddingTop: '1px' }} >
        <Container >
          <section >
            <h2 > {t('description.title1')} </h2>
            <h4 > {t('description.description1')} </h4>
          </section>
        </Container>
        <section className='large bg-yellow' >
          <Container >
            <SectionEuBanner />
          </Container>
        </section>
        <Container >
          <section >
            <SectionTeam />
          </section>
        </Container>
      </div>
    </div>
    <Footer />
  </>
  )
}

export default AboutUs

AboutUs.propTypes = {
  setSignupVisible: PropTypes.func
}
