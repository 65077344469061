import React from 'react'
import Page404 from '../../assets/images/PageNotFound/PageNotFound.jpg'
import logo from 'assets/images/loghi/logo-footer-black.png'
import configuration from '../../configuration'
import { Container } from '@material-ui/core'
import Button from 'components/Button'

const PageNotFound = (props) => {
  return (
    <Container className="container404" >…
      <a href={configuration.pageLinks.home}>
        <img src={logo} alt="page not found" width='150'/>
      </a>
      <img src={Page404} alt="page not found" width='50%'/>
      <p style={{ textAlign: 'center' }}>
        <Button
          className='btn-primary'
          href={configuration.pageLinks.home}
        >
          Go to Home
        </Button>
      </p>
    </Container>
  )
}
export default PageNotFound
