import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Footer from 'components/Footer'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Parallax from 'components/Parallax'

import SectionBrand from './Sections/SectionBrand'
import SectionContact from './Sections/SectionContact'
import SectionArticoli from './Sections/SectionArticoli'
import MetaTags from 'react-meta-tags'

import aboutUsStyle from 'assets/jss/material-kit-pro-react/views/aboutUsStyle.js'

const useStyles = makeStyles(aboutUsStyle)

const Press = ({ setSignupVisible }) => {
  const { t } = useTranslation(['press'])
  const classes = useStyles()

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
    window.location.href = 'https://www.trusty.id/'
  }, [])

  return (
    <>
      <MetaTags>
        <title>{t('metaTitle')}</title>
        <meta name="description" content={t('metaDescription')} />
        <meta property="og:title" content={t('metaTitle')} />
        {/* <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
      <Header page={2} setSignupVisible={setSignupVisible} />
      <div id='press'>
        <Parallax small className='top'>
          <GridContainer>
            <GridItem md={7} sm={7}>
              <div className='title-page'>
                <h1>{t('top.title')}</h1>
                <h3>{t('top.description')}</h3>
              </div>
            </GridItem>
          </GridContainer>
          <div className='back-image'></div>
        </Parallax>
        <div
          className={classes.main}
          style={{
            paddingTop: '1px',
            backgroundColor: 'rgba(255,255,255,0.96)'
          }}
        >
          <SectionArticoli />

          <SectionBrand />
          <SectionContact />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Press

Press.propTypes = {
  setSignupVisible: PropTypes.func
}
